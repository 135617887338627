import React, { useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore, SvgIconComponent } from "@material-ui/icons";

interface SubMenuEntry {
  selected?: boolean;
  title: string;
  onClick: () => void;
  Icon: SvgIconComponent;
  e2e?: string;
}

interface MenuEntryProps {
  selected?: boolean;
  subEntries?: SubMenuEntry[];
  onClickMenu?: () => void;
  title: string;
  MenuIcon: SvgIconComponent;
  className?: string;
  e2e?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nestedMenu: {
      paddingLeft: theme.spacing(4),
    },
    icons: {
      color: "inherit",
      minWidth: "1.9rem",
    },
  }),
);

const MenuEntry: React.FC<MenuEntryProps> = (props) => {
  const { title, MenuIcon, onClickMenu, subEntries, selected, className, e2e } = props;
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles({});

  const hasEntries = subEntries && subEntries.length > 0;

  const handleMenuOpen = () => {
    if (onClickMenu && !hasEntries) {
      onClickMenu();
    } else {
      setOpen(!isOpen);
    }
  };

  let isMenuSelected = selected;

  if (hasEntries && subEntries) {
    isMenuSelected = !!subEntries.find((entry) => entry.selected === true);
  }

  return (
    <React.Fragment>
      <ListItem button onClick={handleMenuOpen} selected={isMenuSelected} className={className} data-e2e={e2e}>
        <ListItemIcon className={classes.icons}>
          <MenuIcon />
        </ListItemIcon>
        <ListItemText primary={title} />
        {!hasEntries ? null : isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {hasEntries && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {hasEntries &&
              subEntries &&
              subEntries.map((entry, index) => {
                const { title, onClick, Icon, selected, e2e } = entry;
                return (
                  <ListItem
                    data-e2e={e2e}
                    key={index}
                    button
                    className={classes.nestedMenu}
                    onClick={onClick}
                    selected={selected}
                  >
                    <ListItemIcon className={classes.icons}>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItem>
                );
              })}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default MenuEntry;
