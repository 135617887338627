import validator from "validator";
import APIJSONResponse, { parseAPIJSONData } from "./responseTypes/APIJSONResponse";
import Jsona from "@asedweb/jsona";
import { TFunction } from "i18next";
import { TJsonApiBody } from "@asedweb/jsona/lib/JsonaTypes";
import { getOauthTokens, removeTokens, saveOauthTokens } from "../../utils/UserUtils";
import Endpoints from "./Endpoints";
import LoginResponse from "./responseTypes/LoginResponse";
import PATHS from "../../config/routes/Paths";

export type methodType = "POST" | "GET" | "PATCH" | "DELETE";

class APIManager {
  endpoint: string;
  showNotificationError: (msg: string, isSticky?: boolean, onClose?: () => void) => void;
  t: TFunction;

  constructor(
    endpoint: string,
    showNotificationError: (msg: string, isSticky?: boolean, onClose?: () => void) => void,
    t: TFunction,
  ) {
    this.endpoint = endpoint;
    this.showNotificationError = showNotificationError;
    this.t = t;
  }

  private async handleErrors(response: Response) {
    if (response.ok) {
      return;
    }

    switch (response.status) {
      case 500:
      case 503:
        this.showNotificationError(this.t("networkErrors:NaviError"));
        break;
      case 401:
      case 403: {
        const jsonResponse = await response.json();
        const detail = jsonResponse.errors && jsonResponse.errors.length ? jsonResponse.errors[0].detail : "";
        if (detail.indexOf("is not allowed") || detail.indexOf("does not have access")) {
          this.showNotificationError(this.t("networkErrors:noPermissions"));
        } else {
          this.showNotificationError(this.t("networkErrors:LoginError"));
        }

        break;
      }
      case 422: {
        const res = await response.json();
        const det = res.errors && res.errors.length ? res.errors[0].detail : "";
        if (det.indexOf("do not have access")) {
          this.showNotificationError(this.t("networkErrors:noPermissions"));
        }
      }
    }
  }

  async checkUserToken(): Promise<void> {
    const time = new Date().getTime();
    const tokens = getOauthTokens();
    if (!tokens || time < tokens.oauthTokenExpiration) {
      return;
    }

    const formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", tokens.refresh_token);
    formData.append("client_id", process.env.REACT_APP_OAUTH_CLIENT_ID as string);
    formData.append("client_secret", process.env.REACT_APP_OAUTH_CLIENT_SECRET as string);

    const response = await fetch(`${this.endpoint}/${Endpoints.OauthLogin}`, {
      method: "POST",
      body: formData,
    });

    const jsonResponse: LoginResponse = await response.json();
    if (
      !response ||
      (response && response.status === 401 && jsonResponse.error_description === "The refresh token is invalid.")
    ) {
      removeTokens();
      window.location.href = `${PATHS.Login}?error=expiredcredentials`;
      return;
    }

    const currentTs = new Date().getTime();
    saveOauthTokens({
      // eslint-disable-next-line camelcase
      access_token: jsonResponse.access_token,
      // eslint-disable-next-line camelcase
      refresh_token: jsonResponse.refresh_token,
      oauthTokenExpiration: currentTs + jsonResponse.expires_in * 1000,
    });

    return;
  }

  async doRequest<T>(
    requestEndpoint: string,
    method: methodType,
    data: unknown = {},
    params?: Record<string, string>,
    isFullURL = false,
  ): Promise<T | null> {
    const finalEndpoint = isFullURL ? requestEndpoint : `${this.endpoint}/${requestEndpoint}`;

    if (!validator.isURL(finalEndpoint)) {
      return null;
    }

    const url = new URL(finalEndpoint);

    if (!isFullURL && params) {
      url.search = new URLSearchParams(params).toString();
    }

    const requestWithToken =
      ["POST", "PATCH"].includes(method) ||
      requestEndpoint.indexOf(Endpoints.GetCurrentUser) > -1 ||
      requestEndpoint.indexOf(Endpoints.Article) > -1 ||
      requestEndpoint.indexOf(Endpoints.Special) > -1 ||
      requestEndpoint.indexOf(Endpoints.PublishProcess.StarPublish) > -1 ||
      requestEndpoint.indexOf(Endpoints.GetUser) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cars.Brand) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cars.Model) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cars.Version) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cars.Engine) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.Comic) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.DLC) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.Films) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.Game) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.Mobile) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.Products) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.Series) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.Tablet) > -1 ||
      requestEndpoint.indexOf(Endpoints.ImageMedia) > -1 ||
      requestEndpoint.indexOf(Endpoints.GalleryMedia) > -1 ||
      requestEndpoint.indexOf(Endpoints.VideoMedia) > -1 ||
      requestEndpoint.indexOf(Endpoints.Tags) > -1 ||
      requestEndpoint.indexOf(Endpoints.TagsTermPages) > -1 ||
      requestEndpoint.indexOf(Endpoints.LegacyProducts) > -1 ||
      requestEndpoint.indexOf(Endpoints.Blocks) > -1 ||
      requestEndpoint.indexOf(Endpoints.Covers) > -1 ||
      requestEndpoint.indexOf(Endpoints.EntityBar) > -1 ||
      requestEndpoint.indexOf(Endpoints.ManualBlock) > -1 ||
      requestEndpoint.indexOf(Endpoints.RevertArticle) > -1 ||
      requestEndpoint.indexOf(Endpoints.PianoBlock) > -1 ||
      requestEndpoint.indexOf(Endpoints.Products) > -1 ||
      requestEndpoint.indexOf(Endpoints.ProductBrand) > -1 ||
      requestEndpoint.indexOf(Endpoints.ProductType) > -1 ||
      requestEndpoint.indexOf(Endpoints.LiveItems) > -1 ||
      requestEndpoint.indexOf(Endpoints.Cards.dynamicCards) > -1;

    if (requestWithToken) {
      await this.checkUserToken();
    }

    const tokens = getOauthTokens();

    if (requestWithToken && !tokens) {
      return null;
    }

    try {
      const headers: HeadersInit =
        method !== "GET"
          ? {
              "Content-Type": "application/vnd.api+json",
              Accept: "application/vnd.api+json",
            }
          : {};

      if (requestWithToken) {
        headers.Authorization = `Bearer ${tokens?.access_token}`;
      }

      const response = await fetch(url.toString(), {
        method: method,
        body: method === "GET" ? undefined : JSON.stringify(data),
        headers: headers,
      });

      if (!response.ok) {
        this.handleErrors(response);
        return null;
      }

      const jsonResponse: T = await response.json();

      return jsonResponse;
    } catch {
      return null;
    }
  }

  async doTextRequest(
    requestEndpoint: string,
    method: methodType,
    data: Record<string, unknown> = {},
    params?: Record<string, string>,
  ): Promise<string | null> {
    const finalEndpoint = `${this.endpoint}/${requestEndpoint}`;

    if (!validator.isURL(finalEndpoint)) {
      return null;
    }

    const url = new URL(finalEndpoint);

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    await this.checkUserToken();
    const tokens = getOauthTokens();
    if (!tokens) {
      return null;
    }

    try {
      const response = await fetch(url.toString(), {
        method: method,
        body: method === "GET" ? undefined : JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${tokens?.access_token}`,
        },
      });

      if (!response.ok) {
        return null;
      }

      const textResponse = await response.text();

      return textResponse;
    } catch (error) {
      return null;
    }
  }

  async doJSONAPIRequest<T>(
    requestEndpoint: string,
    method: methodType,
    data: unknown = {},
    params?: Record<string, string>,
    autoNext = false,
  ): Promise<T[] | null> {
    const apiJsonResponse = await this.doRequest<APIJSONResponse>(requestEndpoint, method, data, params);

    if (!apiJsonResponse) {
      return null;
    }
    const dataFormatter = new Jsona();
    const formatted = dataFormatter.deserialize(apiJsonResponse as unknown as TJsonApiBody);

    if (autoNext && apiJsonResponse?.links?.next) {
      let stop = false;

      let result: T[] = parseAPIJSONData<T>(formatted);

      while (!stop) {
        const newResponse = await this.doRequest<APIJSONResponse>(
          apiJsonResponse.links.next.href.toString(),
          method,
          {},
          {},
          true,
        );

        if (!newResponse) {
          stop = true;
          continue;
        }

        const newValueFormmated = dataFormatter.deserialize(newResponse as unknown as TJsonApiBody);
        const newResponseParsed = parseAPIJSONData<T>(newValueFormmated);
        result = result.concat(newResponseParsed);

        if (!newResponse.links || !newResponse.links.next) {
          stop = true;
          continue;
        }
      }

      return result;
    }

    return parseAPIJSONData<T>(formatted);
  }

  async doBlobRequest<T>(
    requestEndpoint: string,
    data: Blob,
    headersToLoad?: Record<string, string>,
  ): Promise<T[] | null> {
    await this.checkUserToken();
    try {
      const finalEndpoint = `${this.endpoint}/${requestEndpoint}`;

      if (!validator.isURL(finalEndpoint)) {
        return null;
      }

      const tokens = getOauthTokens();
      if (!tokens) {
        return null;
      }

      const response = await fetch(finalEndpoint, {
        headers: Object.assign(
          {},
          {
            Authorization: `Bearer ${tokens.access_token}`,
            "Content-Type": "application/octet-stream",
            Accept: "application/vnd.api+json",
          },
          headersToLoad,
        ),
        method: "POST",
        body: data,
      });

      if (!response.ok) {
        return null;
      }

      const jsonResponse: string = await response.json();
      const dataFormatter = new Jsona();
      const formatted = dataFormatter.deserialize(jsonResponse);

      return parseAPIJSONData<T>(formatted);
    } catch {
      return null;
    }
  }

  async doLoginRequest<T>(
    requestEndpoint: string,
    method: methodType,
    data: Record<string, unknown> = {},
  ): Promise<T | null> {
    const finalEndpoint = `${this.endpoint}/${requestEndpoint}`;

    if (!validator.isURL(finalEndpoint)) {
      return null;
    }

    const params = {
      // eslint-disable-next-line camelcase
      grant_type: "password",
      // eslint-disable-next-line camelcase
      client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
      // eslint-disable-next-line camelcase
      client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
      ...data,
    };

    const formData = new FormData();
    for (const index in params) {
      formData.append(index, params[index]);
    }

    try {
      const response = await fetch(finalEndpoint.toString(), {
        method: method,
        body: formData,
      });

      if (!response.ok) {
        return null;
      }
      const jsonResponse: T = await response.json();
      return jsonResponse;
    } catch {
      return null;
    }
  }

  checkLoginSatatus = (): boolean => {
    const tokens = getOauthTokens();
    if (tokens) {
      return true;
    } else {
      return false;
    }
  };
}

export default APIManager;
