import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    circularProgress: {
      marginBottom: theme.spacing(),
    },
  }),
);
interface LoadingContextInterface {
  setOpen: (isOpen: boolean) => void;
  setMessage: (value: string) => void;
}

const LoadingContext = React.createContext<LoadingContextInterface>({
  setOpen: () => undefined,
  setMessage: () => undefined,
});

const { Provider } = LoadingContext;

export const useLoading = (): {
  openLoading: (isOpen: boolean) => void;
  setMessage: (value: string) => void;
} => {
  const ctx = useContext(LoadingContext);

  return {
    openLoading: ctx.setOpen,
    setMessage: ctx.setMessage,
  };
};

const LoadingProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation("loading");
  const loadingText = t("loading");
  const [message, setMessage] = useState(loadingText);
  const [isOpen, setOpen] = useState(false);

  return (
    <Provider
      value={{
        setOpen,
        setMessage,
      }}
    >
      <Dialog open={isOpen}>
        <DialogContent className={classes.content}>
          <CircularProgress className={classes.circularProgress} />
          <Typography>{message ? message : t("loading")}</Typography>
        </DialogContent>
      </Dialog>
      {children}
    </Provider>
  );
};

export default LoadingProvider;
