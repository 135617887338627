/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext } from "react";
import { Dialog, DialogContent, Typography, makeStyles, createStyles, Theme, Button } from "@material-ui/core";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiDialog-paperWidthSm": {
        width: "65vw",
        maxWidth: "65vw",
        padding: theme.spacing(3),
      },
    },
    title: {
      display: "block",
      marginBottom: theme.spacing(4),
    },
    content: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    circularProgress: {
      marginBottom: theme.spacing(),
    },
    text: {
      display: "block",
      marginTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    closeBtn: {
      marginTop: theme.spacing(4),
    },
    check: {
      color: green[500],
      marginBottom: theme.spacing(-0.5),
    },
  }),
);

interface PublishContextInterface {
  setOpen: (isOpen: boolean) => void;
  setPublishMessage: (msg: string) => void;
  setTitle: (title: string) => void;
  setPercent: (percent: number) => void;
  setSite: (site: string) => void;
  setPublishType: (val: "prod" | "preview" | "tagPage") => void;
  setPreviewUrl: (val: string) => void;
  setPreviewClientUrl: (val: string) => void;
}

const PublishContext = React.createContext<PublishContextInterface>({
  setOpen: () => {},
  setPublishMessage: () => {},
  setTitle: () => {},
  setPercent: () => {},
  setSite: () => {},
  setPublishType: () => {},
  setPreviewUrl: () => {},
  setPreviewClientUrl: () => {},
});

const { Provider } = PublishContext;

export const usePublishScreen = (): {
  openPublishing: (isOpen: boolean) => void;
  setPublishMessage: (msg: string) => void;
  setPercent: (percent: number) => void;
  setTitle: (title: string) => void;
  setSite: (site: string) => void;
  setPublishType: (val: "prod" | "preview" | "tagPage") => void;
  setPreviewUrl: (val: string) => void;
  setPreviewClientUrl: (val: string) => void;
} => {
  const ctx = useContext(PublishContext);

  return {
    openPublishing: ctx.setOpen,
    setPublishMessage: ctx.setPublishMessage,
    setPercent: ctx.setPercent,
    setTitle: ctx.setTitle,
    setSite: ctx.setSite,
    setPublishType: ctx.setPublishType,
    setPreviewUrl: ctx.setPreviewUrl,
    setPreviewClientUrl: ctx.setPreviewClientUrl,
  };
};

const PublishScreenProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation("publishDialog");

  const [publishMessage, setPublishMessage] = useState("");
  const [title, setTitle] = useState("");
  const [site, setSite] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [percent, setPercent] = useState(0);
  const [publishType, setPublishType] = useState("prod");
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewClientUrl, setPreviewClientUrl] = useState("");

  const handleOpenPreview = () => {
    window.open(previewUrl);
    setOpen(false);
  };

  const handleClientPreviewClick = () => {
    window.navigator.clipboard.writeText(previewClientUrl);
    setOpen(false);
  };

  return (
    <Provider
      value={{
        setOpen,
        setPublishMessage,
        setTitle,
        setPercent,
        setSite,
        setPublishType,
        setPreviewUrl,
        setPreviewClientUrl,
      }}
    >
      <Dialog open={isOpen} className={classes.root}>
        <DialogContent className={classes.content}>
          {percent < 100 && (
            <Typography variant={"h5"} className={classes.title}>
              {publishType === "prod" && t("title", { title: title, site: site })}
              {publishType === "preview" && t("titlePreview", { title: title, site: site })}
              {publishType === "tagPage" && t("titleTagPage")}
            </Typography>
          )}
          {percent === 100 && publishType === "prod" && (
            <Typography variant={"h5"} className={classes.title}>
              <CheckCircleIcon className={classes.check} /> {t("titleSuccess", { title: title, site: site })}
            </Typography>
          )}
          {percent === 100 && publishType === "preview" && (
            <Typography variant={"h5"} className={classes.title}>
              <CheckCircleIcon className={classes.check} /> {t("titleSuccessPreview", { title: title, site: site })}
            </Typography>
          )}

          {percent === 100 && publishType === "tagPage" && (
            <Typography variant={"h5"} className={classes.title}>
              <CheckCircleIcon className={classes.check} /> {t("titleSuccessTagPage")}
            </Typography>
          )}

          <LinearProgressWithLabel value={percent} />
          {percent < 100 && (
            <Typography className={classes.text}>{publishMessage ? publishMessage : t("loading")}</Typography>
          )}
          {percent === 100 && ["tagPage", "prod"].includes(publishType) && (
            <Button
              data-e2e="acceptPublishButton"
              className={classes.closeBtn}
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              {t("common:close")}
            </Button>
          )}
          {percent === 100 && publishType === "preview" && (
            <>
              <Button className={classes.closeBtn} variant="contained" color="secondary" onClick={handleOpenPreview}>
                {t("goToPreview")}
              </Button>
              <Button
                className={classes.closeBtn}
                variant="contained"
                color="secondary"
                onClick={handleClientPreviewClick}
              >
                {t("copyClientPreview")}
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
      {children}
    </Provider>
  );
};

export default PublishScreenProvider;
