import Dexie from "dexie";
import { DraftStoredData } from "./types";

export class DBEDitor extends Dexie {
  drafts: Dexie.Table<DraftStoredData, string>;
  specials: Dexie.Table<DraftStoredData, string>;

  constructor() {
    super("DraftsDataBase", { autoOpen: true });

    // Define tables and indexes
    // (Here's where the implicit table props are dynamically created)
    //
    this.version(1).stores({
      drafts: "uuid, title, date, site",
    });

    this.version(2).stores({
      specials: "uuid, title, date, site",
    });

    // The following lines are needed for it to work across typescipt using babel-preset-typescript:
    this.drafts = this.table("drafts");
    this.specials = this.table("specials");
  }
}
