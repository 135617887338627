import React from "react";

import BlockIcon from "@material-ui/icons/Block";
import { useTranslation } from "react-i18next";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { setDocumentTitle } from "../../utils/Title";
import PageTemplate from "../../components/PageTemplate/PageTemplate";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
});

const NoAccessPage: React.FC = () => {
  setDocumentTitle("error");
  const { t } = useTranslation("sections");
  const classes = useStyles();

  return (
    <PageTemplate>
      <Grid
        className={classes.container}
        container
        direction="column"
        alignItems="center"
        alignContent="center"
        justify="center"
      >
        <Grid container spacing={2} direction="row" alignItems="center" justify="center">
          <Grid item>
            <BlockIcon color="error" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h5" component="h3">
              {t("noAccess")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default NoAccessPage;
