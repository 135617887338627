import React, { Fragment } from "react";
import NotficiationProvider from "./components/common/Notifications/Notifications";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./config/theme/Theme";
import { NoSsr } from "@material-ui/core";
import LoadingProvider from "./components/common/LoadingModal/LoadingModal";
import PublishScreenProvider from "./components/common/PublishModal/PublishModal";
import App from "./App";
import EasterEgg from "./components/common/EasterEgg/EasterEgg";

const Infrastructure: React.FC = () => {
  return (
    <Fragment>
      <NoSsr>
        <StylesProvider>
          <MuiThemeProvider theme={theme}>
            <EasterEgg />
            <CssBaseline />
            <NotficiationProvider>
              <LoadingProvider>
                <PublishScreenProvider>
                  <App />
                </PublishScreenProvider>
              </LoadingProvider>
            </NotficiationProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </NoSsr>
    </Fragment>
  );
};

export default Infrastructure;
