import React, { ReactNode, useCallback } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { List, SwipeableDrawer } from "@material-ui/core";
import clsx from "clsx";
import { useAppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import PATHS from "../../../config/routes/Paths";
import { useHistory } from "react-router-dom";
import { removeCurrentUser, removeTokens } from "../../../utils/UserUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      "& .MuiDrawer-paper": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        width: "15rem",
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
          width: "21rem",
        },
      },
      "& .MuiList-root > svg": {
        display: "block",
        margin: "0 auto 1rem",
      },
    },
    logOut: {
      bottom: "0",
      width: "100%",
      padding: " 0 1rem",
      "& p": {
        display: "inline-block",
        marginRight: "0.3rem",
        fontSize: "0.9rem",
      },
      "& p:nth-child(2)": {
        color: theme.palette.secondary.dark,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  }),
);

interface MenuDrawerProps {
  children: ReactNode;
  isOpen: boolean;
  onCloseDrawer: () => void;
  onOpenDrawer: () => void;
  className?: string;
}
const MenuDrawer: React.FC<MenuDrawerProps> = (props) => {
  const classes = useStyles({});
  const { isOpen, onOpenDrawer, onCloseDrawer, className } = props;
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const { currentUser, setUserAuthenticated, apiManager, setApiManager } = useAppContext();
  const { t } = useTranslation();
  const history = useHistory();

  const doLogout = useCallback(async () => {
    removeTokens();
    setUserAuthenticated(false);
    setApiManager(apiManager);
    removeCurrentUser();
    history.push(PATHS.Login);
  }, [apiManager, history, setApiManager, setUserAuthenticated]);

  return (
    <SwipeableDrawer
      data-e2e="drawerMenu"
      open={isOpen}
      onClose={onCloseDrawer}
      onOpen={onOpenDrawer}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      className={clsx(classes.menu, className)}
    >
      <List>{props.children}</List>
      <div className={classes.logOut}>
        <p>{t("menu:article:messageLogout", { username: currentUser.name })}</p>
        <p data-e2e="logoutBtn" onClick={doLogout}>
          {t("menu:article:logout")}
        </p>
      </div>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
