import React, { ReactNode } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { getToolbarHeigth } from "./Components/MenuStylesVariables";
import MenuTopBar from "./Components/MenuTopBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    wrapper: {
      width: "100%",
      maxHeight: "100vh",
      overflow: "hidden",
      height: "100%",
      display: "grid",
      gridTemplateRows: `${theme.mixins.toolbar.minHeight}px auto`,
      "@media (min-width:0px) and (orientation: landscape)": {
        gridTemplateRows: `${getToolbarHeigth(theme, "@media (min-width:0px) and (orientation: landscape)")}px auto`,
      },
      "@media (min-width:600px)": {
        gridTemplateRows: `${getToolbarHeigth(theme, "@media (min-width:600px)")}px auto`,
      },
    },
    content: (props: { spacing: number }) => {
      return {
        flexGrow: 1,
        width: "100%",
        //width: "100vh",
        overflow: "auto",
        padding: theme.spacing(props.spacing),
        position: "relative",
        "& .warning": {
          background: "#EED202",
          margin: "0 0 1rem",
          padding: "0.7rem",
          color: "#000",
          position: "relative",
          opacity: "0.8",
          "& .bold": {
            fontWeight: "bold",
          },
        },
      };
    },
    menuContent: {
      overflowX: "auto",
      padding: theme.spacing(0, 3, 0, 3),
    },
  }),
);

interface PageTemplateProps {
  topMenuItems?: ReactNode;
  avoidPadding?: boolean;
  programmedArticle?: boolean;
  e2e?: string;
}

const PageTemplate: React.FC<PageTemplateProps> = (props) => {
  const pagePadding = props.programmedArticle === false || props.programmedArticle === undefined ? 2 : 0;

  const classes = useStyles({ spacing: pagePadding });

  return (
    <div className={classes.wrapper} data-e2e={props.e2e}>
      <MenuTopBar contentClassName={props.avoidPadding ? undefined : classes.menuContent}>
        {props.topMenuItems}
      </MenuTopBar>
      <main id={"main"} className={classes.content}>
        {props.children}
      </main>
    </div>
  );
};

export default PageTemplate;
