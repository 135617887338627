import { convertToRaw, convertFromRaw } from "draft-js";
import { Author, DraftEditorInfo, DraftStoredData, MetadataInfo } from "./types";
import { getUuid } from "../../../utils";
import _ from "lodash";
import { DBEDitor } from "./DBEditor";
import APIManager from "../../../api/back/APIManager";
import getAuthorById from "../../../api/back/requests/metadata/authors/getAuthorById";
import Dexie from "dexie";
import { AirtableRecord } from "../../../api/airtable/model/AirtableRecord";
import AirtableUtils from "../AirtableUtils/AirtableUtils";
import { checkSiteContentType, NEWS } from "../../../constants/contentType";
import { SitesEntries } from "../../../api/back/model/SitesEntries";

const db = new DBEDitor();

export type contentNode =
  | "articles"
  | "specials"
  | "covers"
  | "dynamicCard"
  | "entityBar"
  | "product"
  | "featuredContents";

type CurrentUser = {
  uuid: string;
  name: string;
  author?: string;
};

const ARTICLES_STORED_DATA_KEY = "ArticleDraftsIndexs";
const SPECIALS_STORED_DATA_KEY = "SpecialsDraftsIndexs";

const getDraftArticlesIndex = () => {
  const JSONArticleDraft = localStorage.getItem(ARTICLES_STORED_DATA_KEY);

  if (!JSONArticleDraft) {
    return null;
  }

  return JSON.parse(JSONArticleDraft) as string[];
};

const setDraftArticlesIndex = (index: string[]) => {
  localStorage.setItem(ARTICLES_STORED_DATA_KEY, JSON.stringify(index));
};

const getDraftSpecialsIndex = (): string[] | null => {
  const JSONArticleDraft = localStorage.getItem(SPECIALS_STORED_DATA_KEY);

  if (!JSONArticleDraft) {
    return null;
  }

  return JSON.parse(JSONArticleDraft) as string[];
};

const setDraftSpecialsIndex = (index: string[]) => {
  localStorage.setItem(SPECIALS_STORED_DATA_KEY, JSON.stringify(index));
};

const getDraftArticles = async (type: contentNode): Promise<DraftStoredData[] | null> => {
  let index;
  if (type === "articles") {
    index = getDraftArticlesIndex();
  } else {
    index = getDraftSpecialsIndex();
  }

  if (!index) {
    return null;
  }

  const result: DraftStoredData[] = [];

  if (type === "articles") {
    for (const key of index) {
      const draft = await db.drafts.where("uuid").equals(key).first();

      if (draft) {
        result.push(draft);
      }
    }
  } else {
    for (const key of index) {
      const draft = await db.specials.where("uuid").equals(key).first();

      if (draft) {
        result.push(draft);
      }
    }
  }
  return result;
};

export const importArticleFromJsonFile = (data: DraftStoredData): void => {
  let draftMapIndex = getDraftArticlesIndex();

  if (!draftMapIndex) {
    draftMapIndex = [];
  }

  db.drafts.put(data);

  if (!draftMapIndex.includes(data.uuid)) {
    draftMapIndex.push(data.uuid);
    setDraftArticlesIndex(draftMapIndex);
  }
};

const persistDraft = async (type: contentNode, data: DraftStoredData): Promise<void> => {
  const table = type === "specials" ? db.specials : db.drafts;
  db.transaction("rw!", table, () => table.put(data));
};

export const saveDraftArticle = (type: contentNode, editorInfo: DraftEditorInfo): void => {
  let draftMapIndex: string[] | null;
  if (type === "articles") {
    draftMapIndex = getDraftArticlesIndex();
  } else {
    draftMapIndex = getDraftSpecialsIndex();
  }

  if (!draftMapIndex) {
    draftMapIndex = [];
  }

  const { title, abstract, editorContent, ...others } = editorInfo;

  const draft: DraftStoredData = {
    title: title ? title : "",
    abstract: abstract ? convertToRaw(abstract) : undefined,
    editorContent: editorContent ? convertToRaw(editorContent) : undefined,
    ...others,
    date: Date.now(),
  };

  persistDraft(type, draft);

  if (!draftMapIndex.includes(editorInfo.uuid)) {
    draftMapIndex.push(editorInfo.uuid);
    type === "articles" ? setDraftArticlesIndex(draftMapIndex) : setDraftSpecialsIndex(draftMapIndex);
  }
};

const loadDraftArticles = async (nodeType: contentNode, uuidToSearch: string): Promise<DraftEditorInfo | null> => {
  const table = nodeType === "articles" ? db.drafts : db.specials;
  const draft = await table.where("uuid").equals(uuidToSearch).first();

  if (!draft) {
    return null;
  }

  const { abstract, editorContent, ...others } = draft;

  const info: DraftEditorInfo = { ...others };

  if (abstract) {
    info.abstract = convertFromRaw(abstract);
  }
  if (editorContent) {
    info.editorContent = convertFromRaw(editorContent);
  }

  return info;
};

const clearDraftArticle = (nodeType: contentNode, uuid: string): Dexie.Promise<void> => {
  const index = getDraftArticlesIndex();

  if (index !== null && index.includes(uuid)) {
    const indexWithouValue = _.without(index, uuid);
    setDraftArticlesIndex(indexWithouValue);
  }

  const table = nodeType === "articles" ? db.drafts : db.specials;

  return table.delete(uuid);
};

const replaceDraftArticleKey = async (
  nodeType: contentNode,
  currentUuid: string,
  nextUuid: string,
): Promise<boolean> => {
  const articleDraftIndex = getDraftArticlesIndex();

  if (articleDraftIndex === null || !articleDraftIndex.includes(currentUuid)) {
    return false;
  }

  const newIndex = _.without(articleDraftIndex, currentUuid);
  newIndex.push(nextUuid);

  const article = await loadDraftArticles(nodeType, currentUuid);

  if (!article) {
    return false;
  }

  article.uuid = nextUuid;

  const result = await db.transaction("rw", db.drafts, async () => {
    const article = await db.drafts.get(currentUuid);

    if (!article) {
      return false;
    }

    db.drafts.delete(currentUuid);
    article.uuid = nextUuid;
    db.drafts.put(article);

    return true;
  });

  if (result) {
    setDraftArticlesIndex(newIndex);
    return true;
  } else {
    return false;
  }
};

export const getDefaultMetadata: (seoTitle?: string, authors?: Author[]) => MetadataInfo = (
  seoTitle = "",
  authors = [],
) => {
  const metadata: MetadataInfo = {
    accessType: "abierto",
    urlCanonical: "",
    contentType: "4e22dfa6-0110-45a9-8000-abe4c83b053e",
    seoTitle,
    metaDescription: "",
    categories: null,
    tags: [],
    cards: [],
    authors: authors,
    updatePathauto: false,
    layout: "default",
  };

  return metadata;
};

export const getDefaultDrafEditorInfo: (site?: string, seoTitle?: string, authors?: Author[]) => DraftEditorInfo = (
  site = "",
  seoTitle = "",
  authors = [],
) => {
  return {
    uuid: getUuid(),
    title: "",
    date: Date.now(),
    site,
    updateChangedDate: false,
    moderationState: "draft",
    bodyMedias: [],
    metadataValues: getDefaultMetadata(seoTitle, authors),
    mainMediaValue: {
      images: [],
      caption: "",
    },
    availableAnchors: [],
    status: false,
    appCreated: "ada",
    appPublished: null,
    inUse: false,
    isOptimized: null,
    airtableID: null,
    mediaEmbeds: [],
  };
};

const initDraftArticle = async (
  nodeType: contentNode,
  site: string,
  currentUser: CurrentUser,
  apiManager: APIManager,
  title = "",
): Promise<DraftEditorInfo> => {
  const authors = [];
  if (currentUser && currentUser.author && apiManager) {
    const author = await getAuthorById(apiManager, currentUser.author, site);
    if (author) {
      authors.push(author);
    }
  }

  const initDraft = getDefaultDrafEditorInfo(site, title, authors);

  saveDraftArticle(nodeType, initDraft);

  return initDraft;
};

const initDraftArticleFromAirtable = async (
  site: string,
  currentUser: CurrentUser,
  apiManager: APIManager,
  airtableData: AirtableRecord,
): Promise<DraftEditorInfo> => {
  const authors = [];
  if (currentUser && currentUser.author && apiManager) {
    const author = await getAuthorById(apiManager, currentUser.author, site);
    if (author) {
      authors.push(author);
    }
  }

  const draft = getDefaultDrafEditorInfo(site, "", authors);

  draft.title = airtableData.fields.Titular;
  draft.airtableProposalID = airtableData.id;
  draft.metadataValues.contentType = checkSiteContentType(
    site as SitesEntries,
    airtableData.fields["Tipo de Contenido"],
  )
    ? AirtableUtils.prepareContentType(airtableData)
    : NEWS;
  draft.metadataValues.categories = await AirtableUtils.prepareCategories(apiManager, airtableData, site);
  draft.metadataValues.tags = await AirtableUtils.prepareTags(apiManager, airtableData, site);
  draft.proposalData = airtableData;
  saveDraftArticle("articles", draft);
  return draft;
};

const loadOrInitDraftArticle = async (
  nodeType: contentNode,
  uuid: string,
  site: string,
  currentUser: CurrentUser,
  apiManager: APIManager,
  title?: string,
): Promise<DraftEditorInfo> => {
  const draft = await loadDraftArticles(nodeType, uuid);

  return draft ? draft : initDraftArticle(nodeType, site, currentUser, apiManager, title);
};

const deleteOldDrafts = async (): Promise<void> => {
  const beforedate = new Date();
  const lastWeek = new Date(new Date().setDate(beforedate.getDate() - 7));
  await db.transaction("rw", db.drafts, async () => {
    const drafts = db.drafts.where("date").belowOrEqual(lastWeek.getTime());
    drafts.each(async (d) => {
      return await db.drafts.delete(d.uuid);
    });
  });

  await db.transaction("rw", db.specials, async () => {
    const drafts = db.specials.where("date").belowOrEqual(lastWeek.getTime());
    drafts.each(async (d) => {
      return await db.specials.delete(d.uuid);
    });
  });

  return;
};

const getDraft = async (nodeType: contentNode, uuid: string): Promise<DraftStoredData | undefined> => {
  const table = nodeType === "articles" ? db.drafts : db.specials;

  const draft = await table.where("uuid").equals(uuid).first();
  return draft;
};

const addAirtableIdToDraft = async (uuid: string, airtableId: string, ts: number): Promise<void> => {
  const draft = await getDraft("articles", uuid);
  if (!draft) {
    return;
  }

  draft.airtableID = airtableId;
  draft.naviSavedDate = ts;

  await persistDraft("articles", draft);
};

export default {
  initDraftArticle,
  clearDraftArticle,
  replaceDraftArticleKey,
  getDraftArticles,
  loadDraftArticles,
  loadOrInitDraftArticle,
  saveDraftArticle,
  deleteOldDrafts,
  getDraft,
  persistDraft,
  addAirtableIdToDraft,
  initDraftArticleFromAirtable,
};
