export default (): string => {
  const url = window.location.href;
  let sentryEnv = "Production";

  if (url.includes("ada-demo1")) {
    sentryEnv = "Demo1";
  } else if (url.includes("ada-demo2")) {
    sentryEnv = "Demo2";
  } else if (url.includes("ada-demo3")) {
    sentryEnv = "Demo3";
  } else if (url.includes("ada-testing")) {
    sentryEnv = "Testing";
  } else if (url.includes("ada-devel")) {
    sentryEnv = "Develop";
  } else if (url.includes("ada-staging")) {
    sentryEnv = "Staging";
  } else if (url.includes("docker.local")) {
    sentryEnv = "Local";
  } else {
    sentryEnv = "Production";
  }

  return sentryEnv;
};
