/* eslint-disable require-atomic-updates */
import APIManager from "../../APIManager";
import Endpoints from "../../Endpoints";
import GetUserResponse from "../../responseTypes/GetUserResponse";
import { CurrentUser } from "../../responseTypes/LoginResponse";

export default async (apiManager: APIManager, uuid: string): Promise<CurrentUser | null> => {
  const r = await apiManager.doJSONAPIRequest<GetUserResponse>(`${Endpoints.GetUser}/${uuid}`, "GET");
  if (r === null) {
    return null;
  }

  const roles = r[0].roles.map((e) => e.id);
  const currentUser = { uuid: "", name: "", author: "", roles: roles } as CurrentUser;

  currentUser.uuid = r[0].id;
  currentUser.name = r[0].name;
  currentUser.author = r[0].field_author?.id;

  return currentUser;
};
