import { Author } from "../../../../../components/Editor/EditorUtils/types";
import APIManager from "../../../APIManager";
import Endpoints from "../../../Endpoints";
import UserResponse from "../../../responseTypes/UserResponse";
import { prepareAuthor } from "./AuthorRequestsUtils";

export default async (apiManager: APIManager, id: string, site: string): Promise<Author | null> => {
  const users = await apiManager.doJSONAPIRequest<UserResponse>(`${Endpoints.GetAuthor}/${id}`, "GET", {});

  if (users !== null && users.length > 0) {
    const author: Author = { name: users[0].name, id: users[0].id, site: users[0].field_site };
    const preparedAuthor = prepareAuthor(author, site);
    return preparedAuthor;
  } else {
    return null;
  }
};
