import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContextProvider, useContextInApp } from "./context/AppContext";
import Routes from "./config/routes/Routes";
import { makeStyles } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
/** No Code Splitting for this 2 Components. There are base components */
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import getEnvForsentry from "./utils/getEnvForsentry";

if (getEnvForsentry() !== "Local") {
  Sentry.init({
    dsn: "https://38a375588d464aac826c11972aba3625@o481468.ingest.sentry.io/5530194",
    environment: getEnvForsentry(),
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const useStyles = makeStyles({
  "@global": {
    html: {
      height: "100%",
    },
    body: {
      height: "100%",
      paddingRight: "0 !important",
    },
    "#root": {
      height: "100%",
    },
  },
});

const App: React.FC = () => {
  const contextValues = useContextInApp();
  useStyles();

  return (
    <AppContextProvider value={contextValues}>
      <Router>
        <Suspense fallback={<LoadingPage />}>
          <Routes />
        </Suspense>
      </Router>
    </AppContextProvider>
  );
};

export default getEnvForsentry() === "Local" ? App : Sentry.withProfiler(App);
