import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const drawerWidth = 240;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getToolbarHeigth = (theme: Theme, queryMedia: string) => {
  const defaultHeight = "56"; // obtained via material-ui doc
  const height = theme.mixins.toolbar.minHeight ? theme.mixins.toolbar.minHeight : defaultHeight;

  if (theme.mixins.toolbar[queryMedia]) {
    const cssQuery = theme.mixins.toolbar[queryMedia] as CSSProperties;
    const queryHeight = cssQuery.minHeight;

    return queryHeight ? queryHeight : defaultHeight;
  }

  return height;
};
