import APIManager from "../../../APIManager";
import Endpoints from "../../../Endpoints";
import Category from "../../../model/Categories/Category";

export default async (apiManager: APIManager, name: string, site: string): Promise<Category | null> => {
  const filters = {
    include: "parent",
    "filter[title-filter][condition][path]": "name",
    "filter[title-filter][condition][operator]": "=",
    "filter[title-filter][condition][value]": name,
    "filter[site]": site,
  };

  const response = await apiManager.doJSONAPIRequest<Category>(`${Endpoints.Categories}`, "GET", {}, filters);

  if (response === null || response.length !== 1) {
    return null;
  }

  return response[0];
};
