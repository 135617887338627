/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import Konami from "react-konami-code";
import { makeStyles, Grow, Zoom } from "@material-ui/core";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import EasterImage from "./resources/Easter.png";

const EASTER_MSG = `“Anímate, Brian. Ya sabes lo que dicen:
La vida es tan mala a veces.
Te enfermas y te enfureces.
Otras cosas a veces,
ya son otras nueces.
Cuando en la vida te crees que enloqueces,
no protestes; ve a por peces
y ya verás que no hay que perder el compás.”`;

const KEYS = {
  UP: 38,
  DOWN: 40,
  A: 65,
  B: 66,
};

const KONAMI_CODE = [KEYS.UP, KEYS.DOWN, KEYS.UP, KEYS.DOWN, KEYS.A, KEYS.B, KEYS.A, KEYS.B];
const TIMEOUT = 60000;

const useStyles = makeStyles({
  container: {
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: 100000000000000,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  image: {
    top: 500,
    left: 400,
    position: "absolute",
  },
  image2: {
    top: 200,
    left: 800,
    position: "absolute",
  },
});

const EasterEgg: React.FC = () => {
  const classes = useStyles();
  const [isVisible, setVisible] = useState(false);
  const { width, height } = useWindowSize();

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimated(!animated);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Konami
      timeout={TIMEOUT}
      action={() => {
        console.log(EASTER_MSG);
        setVisible(true);
      }}
      onTimeout={() => {
        console.log("BYE!");
        setVisible(false);
      }}
      code={KONAMI_CODE}
    >
      {isVisible && (
        <div className={classes.container}>
          <iframe
            title="easter egg :)"
            frameBorder="0"
            width="480"
            height="270"
            src="https://www.dailymotion.com/embed/video/xb3kql?autoPlay=1"
            allowFullScreen
            allow="autoplay"
          ></iframe>
          <Zoom in={animated}>
            <Grow in={animated} style={{ transitionDelay: animated ? "500ms" : "0ms" }}>
              <img className={classes.image} src={EasterImage} alt="easter" />
            </Grow>
          </Zoom>

          <Zoom in={!animated}>
            <Grow in={!animated} style={{ transitionDelay: !animated ? "500ms" : "0ms" }}>
              <img className={classes.image2} src={EasterImage} alt="easter" />
            </Grow>
          </Zoom>
          <Confetti width={width} height={height} />
        </div>
      )}
    </Konami>
  );
};

export default EasterEgg;
