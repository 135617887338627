import React, { useEffect } from "react";
import { Route, RouteProps, Redirect } from "react-router";
import { useAppContext } from "../../context/AppContext";
import Path from "./Paths";
import APIManager from "../../api/back/APIManager";
import persistantUtils, { contentNode } from "../../components/Editor/EditorUtils/persistantUtils";
import NoAccessPage from "../../pages/NoAccessPage/NoAccessPage";
import userRoles from "../../constants/userRoles";
import { getCurrentUserData } from "../../utils/UserUtils";

interface PrivateRouteProps extends RouteProps {
  isArticle?: true;
  nodeType?: contentNode;
  minRol?: string[];
}

const getAccessForComponent = (minRoles: string[], currentUserRoles: string[]) => {
  if (currentUserRoles.includes(userRoles.ADMIN) || !minRoles.length) {
    return true;
  }
  return minRoles.some((e) => currentUserRoles.includes(e));
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...otherProps }) => {
  const { userAuthenticated, setUserAuthenticated, apiManager, setApiManager, setCurrentUser } = useAppContext();
  const [userLogged, setUserLogged] = React.useState(userAuthenticated);
  const [userLoading, setUserLoading] = React.useState(true);
  const [articleLoading, setArticleLoading] = React.useState(false);
  const { currentUser } = useAppContext();
  const [isAccesible, setIsAccessible] = React.useState(
    otherProps.minRol && currentUser.roles.length > 0
      ? getAccessForComponent(otherProps.minRol, currentUser.roles)
      : true,
  );

  useEffect(() => {
    const checkUserStatus = async (apiManager: APIManager) => {
      const isUserLogged = apiManager.checkLoginSatatus();
      if (isUserLogged) {
        const currentUser = await getCurrentUserData(apiManager);
        if (currentUser) {
          setCurrentUser(currentUser);
        }

        setArticleLoading(false);
        setUserLogged(true);
        setUserAuthenticated(true);
      }

      setUserLoading(false);
    };

    if (!userAuthenticated) {
      checkUserStatus(apiManager);
      persistantUtils.deleteOldDrafts();
    } else {
      setIsAccessible(getAccessForComponent(otherProps.minRol ?? [], currentUser.roles));
      setUserLoading(false);
    }
  }, [
    apiManager,
    currentUser.roles,
    otherProps.isArticle,
    otherProps.location,
    otherProps.minRol,
    otherProps.nodeType,
    setApiManager,
    setCurrentUser,
    setUserAuthenticated,
    userAuthenticated,
  ]);

  return userLoading || articleLoading ? (
    <div></div>
  ) : userLogged ? (
    !isAccesible ? (
      <NoAccessPage />
    ) : (
      <Route {...otherProps}>{children}</Route>
    )
  ) : (
    <Redirect to={Path.Login} />
  );
};

export default PrivateRoute;
