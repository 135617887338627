export default {
  Login: "user/login?_format=json",
  LoginStatus: "user/login_status?_format=json",
  Logout: "user/logout?_format=json",
  SessionToken: "session/token",
  Categories: "api/category",
  Tags: "api/tags",
  TagsTermPages: "api/term_page/tags",
  Article: "api/article",
  RevertArticle: "rollback/node",
  ImageFile: "api/media/image/field_media_image",
  ImageMedia: "api/media/image",
  GalleryMedia: "api/media/gallery",
  VideoThumbnailFile: "api/media/video/thumbnail",
  VideoMedia: "api/media/video",
  Paragraph: "api/paragraph/body_page",
  ParagraphHC: "api/paragraph/hc_cards",
  ParagraphHCAnalysis: "api/paragraph/analisis",
  NaviProducts: "api/product/default",
  platformsHcAnalysis: "api/taxonomy_term/platforms",
  LegacyProducts: "api/product/default",
  Cards: {
    Mobile: "api/card/mobile",
    Tablet: "api/card/tablet",
    Products: "api/card/product",
    Game: "api/card/game",
    DLC: "api/card/dlc",
    Comic: "api/card/comic",
    Films: "api/card/film",
    Series: "api/card/serie",
    dynamicCards: "api/component/cards_group",
  },
  Cars: {
    Brand: "api/car_brand",
    Model: "api/car_model",
    Version: "api/car_version",
    Engine: "api/car_engine",
  },
  GetUser: "api/user",
  PublishProcess: {
    StarPublish: "aspublish",
    PublishStatus: "aspublish/run",
  },
  GetAuthor: "api/authors",
  GetPreview: "adapreview",
  GetExternalAuthor: "api/external_authors",
  ExternalSites: "api/external_sites",
  OauthLogin: "oauth/token",
  GetCurrentUser: "current/user",
  Special: "api/special",
  Covers: "api/component/page",
  Blocks: "api/component/block",
  EntityBar: "api/component/entitybar",
  Products: "api/product",
  ManualBlock: "api/component/manual_block",
  ProductBrand: "api/product_brand",
  PianoBlock: "api/component/piano_block",
  ProductType: "api/taxonomy_term/product_type",
  ProductTermPage: "api/term_page/product",
  ProductBrandPage: "api/term_page/product_brand",
  SponsorGroups: "api/component/sponsored_group",
  routeTranslator: "es/router/translate-path",
  Redirections: "api/redirection",
  FilmGenres: "api/taxonomy_term/film_genre",
  GamesGenres: "api/taxonomy_term/video_game_genre",
  LiveItems: "api/direct_item/direct_item",
};
