import CryptoJS from "crypto-js";
import APIManager from "../api/back/APIManager";
import getCurrentUser from "../api/back/requests/login/getCurrentUser";
import getUser from "../api/back/requests/users/getUser";
import { CurrentUser } from "../context/AppContext";

export type UserTokens = {
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  refresh_token: string;
  oauthTokenExpiration: number;
};

const encryptTokens = (tokens: UserTokens) => {
  const str = JSON.stringify(tokens);
  return CryptoJS.AES.encrypt(str, process.env.REACT_APP_ENCRYPT_PASS as string).toString();
};

const decryptTokens = (str: string) => {
  const bytes = CryptoJS.AES.decrypt(str, process.env.REACT_APP_ENCRYPT_PASS as string);
  const tokens = bytes.toString(CryptoJS.enc.Utf8);

  return JSON.parse(tokens);
};

export const saveOauthTokens = (tokens: UserTokens): void => {
  if (tokens.access_token && tokens.refresh_token && tokens.oauthTokenExpiration) {
    localStorage.setItem("tokens", encryptTokens(tokens));
  }
};

export const getOauthTokens = (): UserTokens | null => {
  const tokens = localStorage.getItem("tokens");
  if (!tokens) {
    return null;
  }
  return decryptTokens(tokens) as UserTokens;
};

export const removeTokens = (): void => {
  localStorage.removeItem("tokens");
};

const getUserDataFromAPI = async (apiManager: APIManager): Promise<CurrentUser | null> => {
  const currentUser = { uuid: "", name: "", roles: [] } as CurrentUser;
  const tmpUserdata = await getCurrentUser(apiManager);
  if (!tmpUserdata) {
    return null;
  }
  currentUser.name = tmpUserdata.name;
  currentUser.uuid = tmpUserdata.uuid;
  const userData = await getUser(apiManager, currentUser.uuid);

  if (userData && userData.author) {
    currentUser.author = userData.author;
  }
  currentUser.roles = userData?.roles ?? [];

  return currentUser as CurrentUser;
};

export const storeCurrentUser = (user: CurrentUser | undefined): void => {
  localStorage.setItem("currentUser", JSON.stringify(user));
};

export const getCurrentUserData = async (apiManager: APIManager): Promise<CurrentUser | null> => {
  const tmpCurrentUser = localStorage.getItem("currentUser");

  let userData: CurrentUser | null;
  if (!tmpCurrentUser || tmpCurrentUser === "undefined") {
    userData = await getUserDataFromAPI(apiManager);
    if (userData) {
      storeCurrentUser(userData);
    }
  } else {
    userData = JSON.parse(tmpCurrentUser) as CurrentUser;
  }

  if (!userData) {
    return null;
  }

  return userData;
};

export const removeCurrentUser = (): void => {
  localStorage.removeItem("currentUser");
};
