import contentTypes from "./contentTypes.json";
import { SitesEntries } from "../api/back/model/SitesEntries";
import i18next from "i18next";

export const getContentTypes: (site: SitesEntries) => { id: string; name: string }[] = (site: SitesEntries) => {
  switch (site) {
    case "businessinsider":
      return contentTypes.businessinsider;

    case "autobild":
      return contentTypes.autobild;

    case "computerhoy":
      return contentTypes.computerhoy;

    case "hobbyconsolas":
      return contentTypes.hobbyconsolas;

    case "topgear":
      return contentTypes.topgear;

    default:
      return [];
  }
};

export const NEWS = "4e22dfa6-0110-45a9-8000-abe4c83b053e";
export const RANKING = "68ac9227-8cf9-461d-a0b0-bf0514235059";
export const REPORTAGE = "e640f81e-a5b1-4122-be00-aa069cc7ce84";
export const SHOPPING_GUIDE = "9edd8d48-6341-4940-9e09-68ff242fc8ac";
export const TEST = "96cf4f63-eeb6-4981-891c-28afee6749fc";
export const OPINION = "e8249138-2e24-4af0-a50e-14d8799e1678";
export const EXTERNAL_NEW = "e1472e3c-36da-42bc-ad15-2136a4f06633";
export const COMPARATIVE = "be34eecb-2d15-45a7-8693-baa6dd38fdc7";
export const CORE_TESTING = "01fd02f7-bcba-49f0-9741-c8838ae1388c";
export const SPONSORED_CONTENT = "16c51964-7afa-425c-8a80-4e193e736e26";
export const PRACTICAL = "f3fcebea-9b20-47fc-af2e-831711d302a8";
export const LIST = "40823e8d-ee33-4a84-9440-7bbe707b6872";
export const ANALYSIS = "87fc6061-955d-44b0-a800-cb467b6eff20";
export const TUTORIAL = "dfc7fb91-d96a-483c-abba-f0cd794fae54";
export const GUIDE_COVER = "19e527e7-e475-425f-b71e-efac66014c7e";
export const LIVE = "0933d147-b1db-4589-a80d-d15f877225e5";

export const getContentTypeFromUuid = (uuid: string): string => {
  let contentTypeName;
  switch (uuid) {
    case RANKING:
      contentTypeName = i18next.t("contentTypes:ranking");
      break;
    case SHOPPING_GUIDE:
      contentTypeName = i18next.t("contentTypes:shoppingGuide");
      break;
    case REPORTAGE:
      contentTypeName = i18next.t("contentTypes:reportage");
      break;
    case TEST:
      contentTypeName = i18next.t("contentTypes:test");
      break;
    case OPINION:
      contentTypeName = i18next.t("contentTypes:opinion");
      break;
    case EXTERNAL_NEW:
      contentTypeName = i18next.t("contentTypes:externalNews");
      break;
    case COMPARATIVE:
      contentTypeName = i18next.t("contentTypes:comparative");
      break;
    case CORE_TESTING:
      contentTypeName = i18next.t("contentTypes:coreTesting");
      break;
    case SPONSORED_CONTENT:
      contentTypeName = i18next.t("contentTypes:sponsoredContent");
      break;
    case PRACTICAL:
      contentTypeName = i18next.t("contentTypes:practical");
      break;
    case LIST:
      contentTypeName = i18next.t("contentTypes:list");
      break;
    case ANALYSIS:
      contentTypeName = i18next.t("contentTypes:analysis");
      break;
    case TUTORIAL:
      contentTypeName = i18next.t("contentTypes:tutorial");
      break;
    case GUIDE_COVER:
      contentTypeName = i18next.t("contentTypes:guideCover");
      break;
    case LIVE:
      contentTypeName = i18next.t("contentTypes:live");
      break;
    default:
      contentTypeName = i18next.t("contentTypes:news");
      break;
  }

  return contentTypeName;
};

export const getContentTypeUuidFromName = (contentType: string): string | null => {
  let output;
  switch (contentType) {
    case i18next.t("contentTypes:news"):
      output = NEWS;
      break;
    case i18next.t("contentTypes:ranking"):
      output = RANKING;
      break;
    case i18next.t("contentTypes:reportage"):
      output = REPORTAGE;
      break;
    case i18next.t("contentTypes:shoppingGuide"):
      output = SHOPPING_GUIDE;
      break;
    case i18next.t("contentTypes:test"):
      output = TEST;
      break;
    case i18next.t("contentTypes:opinion"):
      output = OPINION;
      break;
    case i18next.t("contentTypes:externalNews"):
      output = EXTERNAL_NEW;
      break;
    case i18next.t("contentTypes:comparative"):
      output = COMPARATIVE;
      break;
    case i18next.t("contentTypes:coreTesting"):
      output = CORE_TESTING;
      break;
    case i18next.t("contentTypes:sponsoredContent"):
      output = SPONSORED_CONTENT;
      break;
    case i18next.t("contentTypes:practical"):
      output = PRACTICAL;
      break;
    case i18next.t("contentTypes:list"):
      output = LIST;
      break;
    case i18next.t("contentTypes:analysis"):
      output = ANALYSIS;
      break;
    case i18next.t("contentTypes:tutorial"):
      output = TUTORIAL;
      break;
    case i18next.t("contentTypes:guideCover"):
      output = GUIDE_COVER;
      break;
    case i18next.t("contentTypes:live"):
      output = LIVE;
      break;
    default:
      output = null;
      break;
  }

  return output;
};

export const getContentTypesForArticlesListsBySite = (site: string): string[] => {
  const types = getContentTypes(site as SitesEntries);
  const out = [];
  for (const type of types) {
    out.push(type.name);
  }

  return out;
};

export const checkSiteContentType = (site: SitesEntries, contentType: string): boolean => {
  return contentTypes[site].find((ct: { name: string }) => ct.name === contentType) ? true : false;
};
