const PATHS = {
  Login: "/",
  Article: {
    SelectSite: "/select-site",
    ListArticles: "/article/list",
    NewArticle: "/article",
    ArticleDiff: "/article/diff",
    Drafts: "/drafts",
    Debug: "/debug",
    Tools: "/tools",
    importBI: "/bi-imports",
    OtherSources: "/other-sources",
    FeaturedSelectSite: "/featured/select-site",
    FeaturedManagement: "/featured/edition",
  },
  Specials: {
    SpecialsSites: "/special-select-site",
    Special: "/special",
    SpecialDiff: "/special/diff",
    SpecialsList: "/special/list",
  },
  Cards: {
    Create: "/cards/form",
    List: "/cards/list",
    DynamicCardsSites: "/dynamic-cards/select-site",
    DynamicCardsedition: "/dynamic-cards",
  },
  Covers: {
    SelectSite: "/covers-select-site",
    CoverEdition: "/edit-cover",
  },
  EntityBar: {
    SelectSite: "/entity-bar/select-site",
    EntityBarEdition: "/entity-bar/edit",
  },
  Tags: {
    Dashboard: "/tags",
    EditTagPages: "/tags/edit-pages",
  },
  Products: {
    Add: "/products/new",
    Edit: "/products/edit",
    List: "/products",
    Pages: "/products/pages",
    AddBrand: "/brands/new",
    EditBrand: "/brands/edit",
    BrandsList: "/brands",
    BrandsPages: "/brands/pages",
  },
  Redirections: {
    Form: "/redirections/add",
    List: "/redirections",
  },
};

export const DefaultPath = PATHS.Article.SelectSite;

export default PATHS;
