import React, { useState, useCallback } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useAppContext } from "../../../context/AppContext";
import Paths from "../../../config/routes/Paths";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import MenuDrawer from "./MenuDrawer";
import MenuEntry from "./MenuEntry";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import AddBoxIcon from "@material-ui/icons/AddBox";
import HelpIcon from "@material-ui/icons/Help";
import DescriptionIcon from "@material-ui/icons/Description";
import ReportIcon from "@material-ui/icons/Report";
import MenuIcon from "@material-ui/icons/Menu";
import DraftsIcon from "@material-ui/icons/Drafts";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AdaIcon from "./resources/AdaIcon";
import TranslateIcon from "@material-ui/icons/Translate";
import StarsIcon from "@material-ui/icons/Stars";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ListIcon from "@material-ui/icons/List";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import userRoles from "../../../constants/userRoles";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import MoreIcon from "@material-ui/icons/More";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HttpIcon from "@material-ui/icons/Http";
import CreateIcon from "@material-ui/icons/Create";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      color: theme.palette.primary.contrastText,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      zIndex: theme.zIndex.drawer + 1,
      display: "flex",
    },
    menuContent: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    icon: {
      color: "inherit",
      "& .MuiSvgIcon-root": {
        fontSize: "2.5rem",
      },
    },
    colorIcon: {
      fill: "#ffffff",
    },
  }),
);

interface MenuTopBarProps {
  contentClassName?: string;
}

const MenuTopBar: React.FC<MenuTopBarProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const urlHelp = "https://bit.ly/ada-wiki";
  const urlReport = "https://forms.gle/LvQx7LpKeyBx972P7";
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const { userAuthenticated } = useAppContext();
  const { currentUser } = useAppContext();

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!userAuthenticated) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  const isWebmanager = () => {
    return currentUser.roles.includes(userRoles.WEBMANAGER) || currentUser.roles.includes(userRoles.ADMIN);
  };

  const isTagManager = () => {
    return currentUser.roles.includes(userRoles.TAGS_MANAGER) || currentUser.roles.includes(userRoles.ADMIN);
  };

  const IsProductEntitiesManager = () => {
    return (
      currentUser.roles.includes(userRoles.PRODUCT_ENTITIES_MANAGER) || currentUser.roles.includes(userRoles.ADMIN)
    );
  };

  const IsRedirectionsManager = () => {
    return currentUser.roles.includes(userRoles.REDIRECTIONS_MANAGER) || currentUser.roles.includes(userRoles.ADMIN);
  };

  const isTranslationManager = () => {
    return currentUser.roles.includes(userRoles.TRANSLATION_MANAGER) || currentUser.roles.includes(userRoles.ADMIN);
  };

  const cardsSubEntries = [
    {
      title: t("menu:cards:create"),
      Icon: AddCircleOutlineIcon,
      onClick: () => {
        setOpen(false);
        history.push(Paths.Cards.Create);
      },
      e2e: "createCard",
    },
    {
      title: t("menu:cards:list"),
      Icon: ListIcon,
      onClick: () => {
        setOpen(false);
        history.push(Paths.Cards.List);
      },
      e2e: "cardsList",
    },
  ];

  if (isWebmanager()) {
    cardsSubEntries.push({
      title: t("menu:cards:dynamicCards"),
      Icon: DynamicFeedIcon,
      onClick: () => {
        setOpen(false);
        history.push(Paths.Cards.DynamicCardsSites);
      },
      e2e: "dynamicCards",
    });
  }

  return (
    <div className={classes.appBar}>
      <IconButton onClick={handleDrawerOpen} className={classes.icon} data-e2e="menu-btn">
        <MenuIcon />
      </IconButton>
      <MenuDrawer isOpen={isOpen} onOpenDrawer={handleDrawerOpen} onCloseDrawer={handleDrawerClose}>
        <AdaIcon className={classes.colorIcon} />
        <MenuEntry
          title={t("menu:article:list")}
          MenuIcon={ListAltIcon}
          selected={location.pathname === Paths.Article.ListArticles}
          onClickMenu={() => {
            setOpen(false);
            history.push(Paths.Article.ListArticles);
          }}
          e2e="articleList"
        />
        <MenuEntry
          title={t("menu:article:new")}
          MenuIcon={AddBoxIcon}
          selected={location.pathname === Paths.Article.SelectSite}
          onClickMenu={() => {
            setOpen(false);
            history.push(Paths.Article.SelectSite);
          }}
          e2e="selectSite"
        />
        <MenuEntry
          title={t("menu:article:drafts")}
          MenuIcon={DraftsIcon}
          selected={location.pathname === Paths.Article.Drafts}
          onClickMenu={() => {
            setOpen(false);
            history.push(Paths.Article.Drafts);
          }}
          e2e="draftsList"
        />
        <MenuEntry
          title={t("menu:article:importBI")}
          MenuIcon={TranslateIcon}
          selected={location.pathname === Paths.Article.importBI}
          onClickMenu={() => {
            setOpen(false);
            history.push(Paths.Article.importBI);
          }}
          e2e="translationList"
        />
        {isTranslationManager() && (
          <MenuEntry
            title={t("menu:article:otherSources")}
            MenuIcon={TrackChangesIcon}
            selected={location.pathname === Paths.Article.OtherSources}
            onClickMenu={() => {
              setOpen(false);
              history.push(Paths.Article.OtherSources);
            }}
            e2e="translationList"
          />
        )}
        {isWebmanager() && (
          <MenuEntry
            title={t("menu:article:covers")}
            MenuIcon={ImportantDevicesIcon}
            selected={location.pathname === Paths.Covers.SelectSite}
            onClickMenu={() => {
              setOpen(false);
              history.push(Paths.Covers.SelectSite);
            }}
            e2e="coversSelectSiteBtn"
          />
        )}
        {isWebmanager() && (
          <MenuEntry
            title={t("menu:article:entityBar")}
            MenuIcon={DragHandleIcon}
            selected={location.pathname === Paths.EntityBar.SelectSite}
            onClickMenu={() => {
              setOpen(false);
              history.push(Paths.EntityBar.SelectSite);
            }}
            e2e="entityBarSelectSiteBtn"
          />
        )}
        {(isWebmanager() || isTagManager()) && (
          <MenuEntry
            title={t("menu:article:tags")}
            MenuIcon={MoreIcon}
            selected={location.pathname === Paths.Tags.Dashboard}
            onClickMenu={() => {
              setOpen(false);
              history.push(Paths.Tags.Dashboard);
            }}
            e2e="tagsDashboardBtn"
          />
        )}
        {isWebmanager() && (
          <MenuEntry
            title={t("menu:article:featuredContents")}
            MenuIcon={AssignmentIcon}
            selected={location.pathname === Paths.Article.FeaturedSelectSite}
            onClickMenu={() => {
              setOpen(false);
              history.push(Paths.Article.FeaturedSelectSite);
            }}
            e2e="featuredContentsManager"
          />
        )}
        {(isWebmanager() || IsProductEntitiesManager()) && (
          <MenuEntry
            title={t("menu:products:title")}
            MenuIcon={ShoppingBasketIcon}
            selected={false}
            subEntries={[
              {
                title: t("menu:products:createProduct"),
                Icon: AddCircleIcon,
                onClick: () => {
                  setOpen(false);
                  history.push(Paths.Products.Add);
                },
              },
              {
                title: t("menu:products:productsList"),
                Icon: ListAltIcon,
                onClick: () => {
                  setOpen(false);
                  history.push(Paths.Products.List);
                },
              },
              {
                title: t("menu:products:brandsList"),
                Icon: BusinessIcon,
                onClick: () => {
                  setOpen(false);
                  history.push(Paths.Products.BrandsList);
                },
              },
            ]}
            e2e="productsMenuItem"
          />
        )}
        <MenuEntry
          title={t("menu:cards:title")}
          MenuIcon={ShoppingCartIcon}
          selected={false}
          subEntries={cardsSubEntries}
          e2e="cardsSelectSiteBtn"
        />
        <MenuEntry
          title={t("menu:article:specials")}
          MenuIcon={StarsIcon}
          selected={false}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClickMenu={() => {}}
          subEntries={[
            {
              title: t("menu:article:createSpecials"),
              Icon: FolderSpecialIcon,
              onClick: () => {
                setOpen(false);
                history.push(Paths.Specials.SpecialsSites);
              },
            },
            {
              title: t("menu:article:specialsList"),
              Icon: PlaylistAddCheckIcon,
              onClick: () => {
                setOpen(false);
                history.push(Paths.Specials.SpecialsList);
              },
            },
          ]}
          e2e="specialSelectSite"
        />
        {IsRedirectionsManager() && (
          <MenuEntry
            title={t("menu:redirects:title")}
            MenuIcon={HttpIcon}
            selected={false}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClickMenu={() => {}}
            subEntries={[
              {
                title: t("menu:redirects:form"),
                Icon: CreateIcon,
                onClick: () => {
                  setOpen(false);
                  history.push(Paths.Redirections.Form);
                },
              },
              {
                title: t("menu:redirects:list"),
                Icon: ListIcon,
                onClick: () => {
                  setOpen(false);
                  history.push(Paths.Redirections.List);
                },
              },
            ]}
            e2e="specialSelectSite"
          />
        )}
        <MenuEntry
          title={t("menu:help:title")}
          MenuIcon={HelpIcon}
          selected={false}
          subEntries={[
            {
              title: t("menu:help:editorHelp"),
              Icon: DescriptionIcon,
              onClick: () => window.open(urlHelp),
            },
            {
              title: t("menu:help:reportProblem"),
              Icon: ReportIcon,
              onClick: () => window.open(urlReport),
            },
          ]}
        />
      </MenuDrawer>
      <div className={clsx(classes.menuContent, props.contentClassName)}>{props.children}</div>
    </div>
  );
};

export default MenuTopBar;
