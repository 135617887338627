export const GALLERIES_MIN_ELEMENTS = 2;
export const GALLERY_MAX_ELEMENTS = 15;
export const GALLERY_SLIDER_MAX_ELEMETS = 4;
export const AUTOCOMPLETE_MIN_CHARS = 3;
export const AUTOCOMPLETE_REQUEST_DELAY = 1200;
export const MINIFILES_AUTOCOMPLETE_MIN_CHARS = 1;
export const TAGS_AUTOCOMPLETE_MIN_CHARS = 2;
export const MAX_NUM_CARS = 5;
export const THE_BEST_AND_THE_WORST_MAX_CHARS = 150;
export const ASSESMENT_AND_METACRITIC_MAXH_CHARS = 350;
export const TECH_TOP_RATING = 100;
export const CARS_TOP_RATING = 10;
export const TECH_STEP_RATING = 1;
export const CARS_STEP_RATING = 0.1;
export const ARTICLE_MIN_WORDS = 250;
export const ARTICLE_MIN_LINKS_PER_TYPE = 1;
export const ARTICLES_LIST_NUM_ARTICLES_PER_PAGE = 30;
export const ARTICLE_COLUMNS_KEY = "articleListColumns";
export const ARTICLE_LIST_FILTERS = "articleListFilters";
export const ARTICLE_LIST_FILTERS_REQUEST = "articleListFilterRequest";
export const SPECIALS_LIST_FILTERS = "specialsListFilters";
export const SPECIALS_LIST_FILTERS_REQUEST = "specialsListFilterRequest";
export const TITLE2_KEYBINDING = "ada-title2";
export const TITLE3_KEYBINDING = "ada-title3";
export const CUT = "ada-cut";
export const COPY = "ada-copy";
export const STRIKETHROUGH = "ada-strikethrough";
export const RAW_COPY = "ada-raw-copy";
export const SELECT_ALL = "ada-selectAll";
export const ARTICLE_REDIRECTION_KEY = "articleRedirection";
export const UUID_LENGTH = 36;
export const AXEL_SPRINGER_SPAIN_LICENSE = "Axel Springer Spain";
export const GRAPH_CLIENT_TOKEN = "824045486434138|5d01217e487b524007318e1111148077";
export const MAX_UPLOAD_SIZE = 5242880;
export const BEST_PRACTICES_PARAGRAPH_MAX_LENGTH = 400;
export const GALLERY_LAYOUT_NORMAL = "normal";
export const GALLERY_LAYOUT_HORIZONTAL = "horizontal";
export const ENABLED_AIRTABLE = true;
export const AIRTABLE_MAIN_OPTION = "main";
export const AIRTABLE_PROPOSALS_OPTION = "proposals";
export const MAIN_ELEMENT_MIN_WIDTH = 1200;
export const AUTOREL_NUM_MAX = 3;
export const ENABLE_NEW_PRODUCTS = true;
export const CLONED_PRODUCT_ID = "clonedProduct";
export const FEATURED_GROUPS_NUM_MAX = 10;
export const FEATURED_GROUPS_NUM_MIN = 1;
export const LIVE_ITEMS_PER_PAGE = 10;
