const ADMIN = "aa25e1ef-f3cd-4922-8a00-a7289a022bf7";
const ANONYMOUS = "06ba5bfa-f8d8-42f7-9d02-2fc10a4742f7";
const AUTHENTICATED_USER = "e5d242b9-4001-4f31-9517-2325f25002ae";
const EDITOR = "2b7cb5ee-eb39-4f34-aa00-b0421bee763f";
const OPERATIONS = "714f9885-a150-46c2-b4ed-6ca384599dc5";
const SERVICES_BI = "0659502b-f4f4-4e9f-8a34-aa65dd12fffe";
const SUPER_EDITOR = "ad4525d2-146f-47fd-b64b-754686d16f12";
const TAGS_MANAGER = "cb6fefb3-acde-42b6-8596-ef95b3f6bc93";
const VIP = "fe6f5ede-0141-4ed3-8800-f6aa65f80e17";
const WEBMANAGER = "f5069403-f271-42aa-bc61-97d976a73a99";
const PRODUCT_MANAGER = "1765f4e2-efbd-405e-bfe2-3ab52ad1fdbe";
const PRODUCT_ENTITIES_MANAGER = "a48c0fdd-385e-4355-9a4f-cdc2ea825c50";
const REDIRECTIONS_MANAGER = "4ff33e16-80de-4151-b172-ac1b2ea51fc7";
const TRANSLATION_MANAGER = "4e5d09ca-a11d-486c-8609-d432cc2cc9b1";

export default {
  ADMIN,
  ANONYMOUS,
  AUTHENTICATED_USER,
  EDITOR,
  OPERATIONS,
  SERVICES_BI,
  SUPER_EDITOR,
  TAGS_MANAGER,
  VIP,
  WEBMANAGER,
  PRODUCT_MANAGER,
  PRODUCT_ENTITIES_MANAGER,
  REDIRECTIONS_MANAGER,
  TRANSLATION_MANAGER,
};
