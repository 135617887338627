import { URL } from "url";
import { TJsonaModel } from "@asedweb/jsona/lib/JsonaTypes";

interface APIJSONResponse {
  jsonapi: Record<string, unknown>;
  data: Record<string, unknown> | Record<string, unknown>[];
  included?: Record<string, unknown> | Record<string, unknown>[];
  links?: {
    next?: {
      href: URL;
    };
  };
}

const parseValue = <T>(value: Record<string, unknown> | Record<string, unknown>[] | undefined) => {
  if (!value) {
    const valueEmpty: T[] = [];
    return valueEmpty;
  }

  if ((value as [Record<string, unknown>]).includes === undefined) {
    return [value] as unknown as [T];
  } else {
    return value as unknown as [T];
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseAPIJSONData = <T>(apiResponse: TJsonaModel | TJsonaModel[]) => {
  return parseValue<T>(apiResponse);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseAPIJSONIncluded = <T>(apiResponse: APIJSONResponse) => {
  return parseValue<T>(apiResponse.included);
};

export default APIJSONResponse;
