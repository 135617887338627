import i18next from "i18next";

export const setDocumentTitle = (page: string, text: undefined | string = undefined): void => {
  let title;
  switch (page) {
    case "login":
      title = `${i18next.t("sections:login")} - ada`;
      break;
    case "error":
      title = `${i18next.t("sections:e404")} - ada`;
      break;
    case "loading":
      title = `${i18next.t("sections:loading")} - ada`;
      break;
    case "selectSite":
      title = `${i18next.t("sections:selectSite")} - ada`;
      break;
    case "drafts":
      title = `${i18next.t("sections:drafts")} - ada`;
      break;
    case "dashboard":
      title = `${i18next.t("sections:dashboard")} - ada`;
      break;
    case "devtools":
      title = `${i18next.t("sections:devtools")} - ada`;
      break;
    case "importBI":
      title = `${i18next.t("sections:biImport")} - ada`;
      break;
    case "article":
      title = text ? `${text} - ada` : "ada";
      break;
    case "editCards":
      title = `${i18next.t("sections:editCards")} - ada`;
      break;
    case "addCards":
      title = `${i18next.t("sections:addCards")} - ada`;
      break;
    case "cardsList":
      title = `${i18next.t("sections:cardsList")} - ada`;
      break;
    case "editCover":
      title = `${i18next.t("sections:editCover", { media: text })} - ada`;
      break;
    case "dynamicCardsEdition":
      title = `${i18next.t("sections:dynamicCards")} - ada`;
      break;
    case "tagsManager":
      title = `${i18next.t("sections:tagsManager")} - ada`;
      break;
    case "tagPagesEditor":
      title = `${i18next.t("sections:tagPageEditor")} - ada`;
      break;
    case "entityBarManager":
      title = `${i18next.t("sections:entityBarManager")} - ada`;
      break;
    case "productList":
      title = `${i18next.t("sections:productsLists")} - ada`;
      break;
    case "brandsList":
      title = `${i18next.t("sections:brandsLists")} - ada`;
      break;
    case "featuredManager":
      title = `${i18next.t("sections:featuredManager")} - ada`;
      break;
    case "brandsPagesManager":
      title = `${i18next.t("sections:brandsPages")} - ada`;
      break;
    case "redirectionsForm":
      title = `${i18next.t("sections:redirectionsForm")} - ada`;
      break;
    case "redirectionList":
      title = `${i18next.t("sections:redirectionsList")} - ada`;
      break;
    case "otherSources":
      title = `${i18next.t("sections:otherSources")} - ada`;
      break;

    default:
      title = "ada";
      break;
  }

  document.title = title;
};
