import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Grid, Typography } from "@material-ui/core/";
import { useTranslation } from "react-i18next";
import { setDocumentTitle } from "../../utils/Title";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    overflow: "hidden",
  },
  loadingLabel: {
    textAlign: "center",
    fontSize: "1.5rem",
  },
});

const LoadingPage: React.FC = () => {
  const classes = useStyles({});
  setDocumentTitle("loading");
  const { t } = useTranslation("loading");

  return (
    <Fragment>
      <LinearProgress />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <Grid item>
          <Typography variant="h5" component="h3">
            {t("loading")}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default LoadingPage;
