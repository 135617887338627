import { AirtableRecord } from "../../../api/airtable/model/AirtableRecord";
import APIManager from "../../../api/back/APIManager";
import getCategoryByName from "../../../api/back/requests/metadata/categories/getCategoryByName";
import getTagByName from "../../../api/back/requests/metadata/tag/getTagByName";
import { getContentTypeUuidFromName, NEWS } from "../../../constants/contentType";
import { OptionType } from "../EditorUtils/types";

const prepareContentType = (item: AirtableRecord): string => {
  return getContentTypeUuidFromName(item.fields["Tipo de Contenido"]) ?? NEWS;
};

const isParent = (cat: string, site: string) => {
  if (site === "Hobby Consolas" && ["Juegos", "Entretenimiento", "Hobbycine"].includes(cat)) {
    return true;
  } else if (site === "Auto Bild" && ["Tecnología", "Motos"].includes(cat)) {
    return true;
  }
  return false;
};

const prepareCategories = async (apiManager: APIManager, item: AirtableRecord, site: string): Promise<OptionType[]> => {
  const categories: OptionType[] = [];
  if (!item.fields.Secciones) {
    return categories;
  }

  for (const cat of item.fields.Secciones) {
    if (isParent(cat, item.fields.Site)) {
      continue;
    }
    let catName = cat;

    if (cat.indexOf("/") > -1) {
      catName = cat.split("/")[1].trim();
    }
    const category = await getCategoryByName(apiManager, catName, site);

    if (category) {
      const data = category.parent[0]?.id
        ? ({ label: category.name, value: category.id, parent: category.parent[0].id } as OptionType)
        : ({ label: category.name, value: category.id } as OptionType);

      categories.push(data);
    }
  }

  return categories;
};

const prepareTags = async (apiManager: APIManager, item: AirtableRecord, site: string): Promise<OptionType[]> => {
  const tags: OptionType[] = [];
  if (!item.fields.Tags) {
    return tags;
  }

  for (const tag of item.fields.Tags) {
    const tagData = await getTagByName(apiManager, tag, site);
    if (tagData) {
      tags.push({ label: tagData.name, value: tagData.id } as OptionType);
    }
  }

  return tags;
};

export default {
  prepareContentType,
  prepareCategories,
  prepareTags,
};
