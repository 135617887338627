import APIManager from "../../../APIManager";
import Endpoints from "../../../Endpoints";
import Tag from "../../../model/Tag/Tag";

export default async (apiManager: APIManager, name: string, site: string): Promise<Tag | null> => {
  const filters = {
    "filter[title-filter][condition][path]": "name",
    "filter[title-filter][condition][operator]": "=",
    "filter[title-filter][condition][value]": name,
    "filter[site]": site,
  };

  const response = await apiManager.doJSONAPIRequest<Tag>(`${Endpoints.Tags}`, "GET", {}, filters);

  if (response === null || response.length !== 1) {
    return null;
  }

  return response[0];
};
