import React from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SnackbarProvider } from "notistack";
import { Theme, makeStyles, createStyles } from "@material-ui/core";

type NotificationType = "error" | "warning" | "success" | "info";
const DURATION = 5000;
const MAX_STACK = 5;

export const useNotifications = (): {
  showInfoNotification: (msg: string, isSticky?: boolean, onClose?: () => void) => SnackbarKey;
  showWarningNotification: (msg: string, isSticky?: boolean, onClose?: () => void) => SnackbarKey;
  showSuccessNotification: (msg: string, isSticky?: boolean, onClose?: () => void) => SnackbarKey;
  showErrorNotification: (msg: string, isSticky?: boolean, onClose?: () => void) => SnackbarKey;
} => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = (onCloseCallback: () => void) => (key: string | number) =>
    (
      <React.Fragment>
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
            onCloseCallback();
          }}
        >
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    );

  const showNotification = (
    msg: string,
    variant: NotificationType,
    isSticky = false,
    onClose: () => void = () => undefined,
  ) =>
    enqueueSnackbar(msg, {
      variant,
      persist: isSticky,
      autoHideDuration: DURATION,
      action: action(onClose),
    });

  const showInfoNotification = (msg: string, isSticky = false, onClose: () => void = () => undefined) =>
    showNotification(msg, "info", isSticky, onClose);

  const showWarningNotification = (msg: string, isSticky = false, onClose: () => void = () => undefined) =>
    showNotification(msg, "warning", isSticky, onClose);

  const showSuccessNotification = (msg: string, isSticky = false, onClose: () => void = () => undefined) =>
    showNotification(msg, "success", isSticky, onClose);

  const showErrorNotification = (msg: string, isSticky = true, onClose: () => void = () => undefined) =>
    showNotification(msg, "error", isSticky, onClose);

  return {
    showInfoNotification,
    showWarningNotification,
    showSuccessNotification,
    showErrorNotification,
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      backgroundColor: theme.palette.secondary.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
  }),
);

const NotficiationProvider: React.FC = (props) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={MAX_STACK}
      classes={{
        variantInfo: classes.info,
        variantError: classes.error,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default NotficiationProvider;
