import { TFunction } from "i18next";
import { methodType } from "../back/APIManager";

class AirtableManager {
  mainEndpoint: string;
  proposalsEndpoint: string;
  showNotificationError: (msg: string, isSticky?: boolean, onClose?: () => void) => void;
  t: TFunction;
  token: string;

  constructor(
    endpoint: string,
    proposalsEndpoint: string,
    token: string,
    showNotificationError: (msg: string, isSticky?: boolean, onClose?: () => void) => void,
    t: TFunction,
  ) {
    this.mainEndpoint = endpoint;
    this.proposalsEndpoint = proposalsEndpoint;
    this.showNotificationError = showNotificationError;
    this.t = t;
    this.token = token;
  }

  private async handleErrors(response: Response) {
    if (response.ok) {
      return;
    }

    switch (response.status) {
      case 500:
      case 503:
        this.showNotificationError(this.t("networkErrors:NaviError"));
        break;
      case 401:
        this.showNotificationError(this.t("networkErrors:airtableUnauthorized"));
    }
  }

  async request<T>(
    table: "main" | "proposals",
    method: methodType,
    data: unknown = {},
    params?: Record<string, string>,
    id?: string,
  ): Promise<T | null> {
    try {
      let endpoint = table === "main" ? this.mainEndpoint : this.proposalsEndpoint;
      if (id) {
        endpoint = `${endpoint}/${id}`;
      }

      const url = new URL(endpoint);

      if (params) {
        url.search = new URLSearchParams(params).toString();
      }

      const headers: HeadersInit = {
        Authorization: `Bearer ${this.token}`,
      };

      if (method !== "GET") {
        headers["Content-Type"] = "application/json";
      }

      const parsedUrl = url.toString();

      const response = await fetch(parsedUrl, {
        method: method,
        body: method === "GET" ? undefined : JSON.stringify(data),
        headers: headers,
      });

      if (!response.ok) {
        this.handleErrors(response);
        return null;
      }

      const jsonResponse = await response.json();

      return id ? (jsonResponse as T) : (jsonResponse.records as T);
    } catch {
      return null;
    }
  }
}

export default AirtableManager;
