/* eslint-disable require-atomic-updates */
import APIManager from "../../APIManager";
import Endpoints from "../../Endpoints";
import CurrentUserResponse from "../../responseTypes/CurrentUserResponse";

export default async (
  apiManager: APIManager,
): Promise<{
  uuid: string;
  name: string;
} | null> => {
  const ts = new Date().getTime() as unknown as string;
  const params = { ts: ts };
  const user = await apiManager.doRequest<CurrentUserResponse>(Endpoints.GetCurrentUser, "GET", {}, params);
  if (user) {
    return { uuid: user?.uuid, name: user?.name };
  } else {
    return null;
  }
};
