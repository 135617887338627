import { SitesEntries } from "../api/back/model/SitesEntries";
import { contentNode } from "../components/Editor/EditorUtils/persistantUtils";

export const HOBBYCONSOLAS = "hobbyconsolas";
export const COMPUTERHOY = "computerhoy";
export const AUTOBILD = "autobild";
export const TOPGEAR = "topgear";
export const BUSINESSINSIDER = "businessinsider";
export const HOBBYCONSOLAS_UUID = "3bf6314b-4030-41b7-ba2a-42502dcbcd89";
export const COMPUTERHOY_UUID = "2c45e0bd-afa4-4d02-a656-aeaa2abe36a2";
export const AUTOBILD_UUID = "9158ebf5-62e8-4cc9-886b-7f45410359c1";
export const TOPGEAR_UUID = "3de0e22e-280c-4b1f-ad05-5b39be3c55bd";
export const BUSINESSINSIDER_UUID = "eafa70cd-d518-4681-a964-6af1b545f03e";
export const NEXT_SITES = [HOBBYCONSOLAS, BUSINESSINSIDER, COMPUTERHOY, AUTOBILD, TOPGEAR];
import BILogo from "../pages/Articles/SelectSite/resources/BIlogo.jpg";
import AULogo from "../pages/Articles/SelectSite/resources/IconoAB800px.jpg";
import CHLogo from "../pages/Articles/SelectSite/resources/IconoCH800px.jpg";
import HCLogo from "../pages/Articles/SelectSite/resources/IconoHC800px.jpg";
import TGLogo from "../pages/Articles/SelectSite/resources/Top-Gear-logo.png";
import HobbyCineLogo from "../pages/Articles/SelectSite/resources/hobbycine.jpg";

export const getSiteUuid = (site: SitesEntries): string => {
  let uuid;
  switch (site) {
    case HOBBYCONSOLAS:
      uuid = HOBBYCONSOLAS_UUID;
      break;
    case COMPUTERHOY:
      uuid = COMPUTERHOY_UUID;
      break;
    case AUTOBILD:
      uuid = AUTOBILD_UUID;
      break;
    case TOPGEAR:
      uuid = TOPGEAR_UUID;
      break;
    case BUSINESSINSIDER:
      uuid = BUSINESSINSIDER_UUID;
      break;

    default:
      uuid = "";
      break;
  }

  return uuid;
};

export const getSiteNameFromUuid = (uuid: string): SitesEntries => {
  let name;
  switch (uuid) {
    case HOBBYCONSOLAS_UUID:
      name = HOBBYCONSOLAS;
      break;
    case COMPUTERHOY_UUID:
      name = COMPUTERHOY;
      break;
    case AUTOBILD_UUID:
      name = AUTOBILD;
      break;
    case TOPGEAR_UUID:
      name = TOPGEAR;
      break;
    case BUSINESSINSIDER_UUID:
      name = BUSINESSINSIDER;
      break;

    default:
      name = "";
      break;
  }

  return name as SitesEntries;
};

export const getSiteNameToShow = (site: SitesEntries): string => {
  let name;
  switch (site) {
    case HOBBYCONSOLAS:
      name = "HobbyConsolas";
      break;
    case COMPUTERHOY:
      name = "ComputerHoy";
      break;
    case AUTOBILD:
      name = "AutoBild";
      break;
    case TOPGEAR:
      name = "TopGear";
      break;
    case BUSINESSINSIDER:
      name = "Business Insider";
      break;

    default:
      name = "";
      break;
  }

  return name;
};

export const sitesOptions = [
  { site: BUSINESSINSIDER, nameToShow: getSiteNameToShow(BUSINESSINSIDER), uuid: getSiteUuid(BUSINESSINSIDER) },
  { site: HOBBYCONSOLAS, nameToShow: getSiteNameToShow(HOBBYCONSOLAS), uuid: getSiteUuid(HOBBYCONSOLAS) },
  { site: COMPUTERHOY, nameToShow: getSiteNameToShow(COMPUTERHOY), uuid: getSiteUuid(COMPUTERHOY) },
  { site: AUTOBILD, nameToShow: getSiteNameToShow(AUTOBILD), uuid: getSiteUuid(AUTOBILD) },
  { site: TOPGEAR, nameToShow: getSiteNameToShow(TOPGEAR), uuid: getSiteUuid(TOPGEAR) },
];

export const getEnvDomain = (site: SitesEntries): string => {
  let domain;
  switch (site) {
    case "computerhoy":
      domain = process.env.REACT_APP_BACK_ENDPOINT.replace("navi.axelspringer.es", "computerhoy.com");
      break;
    case "hobbyconsolas":
      domain = process.env.REACT_APP_BACK_ENDPOINT.replace("navi.axelspringer.es", "hobbyconsolas.com");
      domain = domain.replace(/^http(s)?:\/\//gm, "https://www.");
      break;
    case "autobild":
      domain = process.env.REACT_APP_BACK_ENDPOINT.replace("navi.axelspringer.es", "autobild.es");
      domain = domain.replace(/^http(s)?:\/\//gm, "https://www.");
      break;
    case "topgear":
      domain = process.env.REACT_APP_BACK_ENDPOINT.replace("navi.axelspringer.es", "topgear.es");
      domain = domain.replace(/^http(s)?:\/\//gm, "https://www.");
      break;
    case "businessinsider":
      domain = process.env.REACT_APP_BACK_ENDPOINT.replace("navi.axelspringer.es", "businessinsider.es");
      domain = domain.replace(/^http(s)?:\/\//gm, "https://www.");
      break;
    default:
      domain = "";
      break;
  }

  return domain;
};

export const getAirtableName = (siteName: SitesEntries): string => {
  let site;
  switch (siteName) {
    case "computerhoy":
      site = "Computer Hoy";
      break;
    case "hobbyconsolas":
      site = "Hobby Consolas";
      break;
    case "autobild":
      site = "Auto Bild";
      break;
    case "topgear":
      site = "Top Gear";
      break;
    case "businessinsider":
      site = "Business Insider";
      break;
    default:
      site = "";
      break;
  }

  return site;
};

export const sitesInfo = [
  {
    id: "businessinsider",
    src: BILogo,
    title: "Business Insider",
  },
  {
    id: "computerhoy",
    src: CHLogo,
    title: "ComputerHoy",
  },
  {
    id: "hobbyconsolas",
    src: HCLogo,
    title: "HobbyConsolas",
  },
  {
    id: "autobild",
    src: AULogo,
    title: "AutoBild",
  },
  {
    id: "topgear",
    src: TGLogo,
    title: "TopGear",
  },
];

export const getSitesInfo = (
  nodeType: contentNode,
): {
  id: string;
  src: string;
  title: string;
}[] => {
  if (nodeType === "covers" && !sitesInfo.find((el) => el.id === "hobbycine")) {
    return [...sitesInfo, { id: "hobbycine", src: HobbyCineLogo, title: "HobbyConsolas Cine" }];
  } else {
    return sitesInfo;
  }
};
