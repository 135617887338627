import React, { useState, useContext } from "react";
import APIManager from "../api/back/APIManager";
import { useNotifications } from "../components/common/Notifications/Notifications";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import AirtableManager from "../api/airtable/AirtableManager";
import TranslationManager from "../api/translations/TranslationsManager";

export interface CurrentUser {
  uuid: string;
  name: string;
  author?: string;
  roles: string[];
}

export interface AppContextInterface {
  apiManager: APIManager;
  airtableManager: AirtableManager;
  setAirtableManager: (val: AirtableManager) => void;
  userAuthenticated: boolean;
  setUserAuthenticated: (isAuthenticated: boolean) => void;
  setApiManager: (apiManager: APIManager) => void;
  setCurrentUser: (user: CurrentUser) => void;
  currentUser: CurrentUser;
  currentDraftId: string;
  setCurrentDraftId: (val: string) => void;
  translationManager: TranslationManager;
  setTranslationManager: (val: TranslationManager) => void;
}

const AppContext = React.createContext<AppContextInterface>({
  apiManager: new APIManager(process.env.REACT_APP_BACK_ENDPOINT, () => undefined, (() => undefined) as TFunction),
  airtableManager: new AirtableManager(
    process.env.REACT_APP_AIRTABLE_ENDPOINT,
    process.env.REACT_APP_AIRTABLE_PROPOSALS,
    process.env.REACT_APP_AIRTABLE_PAT,
    () => undefined,
    (() => undefined) as TFunction,
  ),
  translationManager: new TranslationManager(
    process.env.REACT_APP_TRANSLATIONS_ENDPOINT,
    process.env.REACT_APP_TRANSLATIONS_TOKEN,
    () => undefined,
    (() => undefined) as TFunction,
  ),
  setTranslationManager: () => undefined,
  setAirtableManager: () => undefined,
  userAuthenticated: false,
  setUserAuthenticated: () => undefined,
  setApiManager: () => undefined,
  currentUser: { name: "", uuid: "", roles: [] },
  setCurrentUser: () => undefined,
  currentDraftId: "",
  setCurrentDraftId: () => undefined,
});

export default AppContext;

export const AppContextProvider = AppContext.Provider;

export const useContextInApp = (): AppContextInterface => {
  const { showErrorNotification } = useNotifications();
  const { t } = useTranslation();
  const [apiManager, setApiManager] = useState(
    new APIManager(process.env.REACT_APP_BACK_ENDPOINT, showErrorNotification, t),
  );

  const [airtableManager, setAirtableManager] = useState(
    new AirtableManager(
      process.env.REACT_APP_AIRTABLE_ENDPOINT,
      process.env.REACT_APP_AIRTABLE_PROPOSALS,
      process.env.REACT_APP_AIRTABLE_PAT,
      showErrorNotification,
      t,
    ),
  );
  const [translationManager, setTranslationManager] = useState(
    new TranslationManager(
      process.env.REACT_APP_TRANSLATIONS_ENDPOINT,
      process.env.REACT_APP_TRANSLATIONS_TOKEN,
      showErrorNotification,
      t,
    ),
  );
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState({ name: "", uuid: "", roles: [] as string[] });
  const [currentDraftId, setCurrentDraftId] = useState("");

  const contextValues: AppContextInterface = {
    apiManager,
    airtableManager,
    setAirtableManager,
    userAuthenticated,
    setUserAuthenticated,
    setApiManager,
    setCurrentUser,
    currentUser,
    currentDraftId,
    setCurrentDraftId,
    translationManager,
    setTranslationManager,
  };

  return contextValues;
};

export const useAppContext = (): AppContextInterface => useContext(AppContext);
