import _ from "lodash";
import { Author } from "../../../../../components/Editor/EditorUtils/types";
import { getSiteUuid } from "../../../../../constants/sites";
import { SitesEntries } from "../../../model/SitesEntries";

export const prepareAuthor = (author: Author, currentSite: string | null): Author => {
  const siteID = getSiteUuid(currentSite as SitesEntries);
  if (currentSite && !_.find(author.site, { id: siteID })) {
    author.site.push({ type: "site", id: siteID });
    author.update = true;
  }

  return author;
};
