import React from "react";
import "./AdaIcon.css";

interface AdaIconProps {
  className?: string;
}

const AdaIcon: React.FC<AdaIconProps> = (props) => {
  return (
    <svg
      className={props.className}
      version="1.1"
      id="a__aufbau"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="78.85px"
      height="21.74px"
      viewBox="0 6.89 78.85 21.74"
      enableBackground="new 0 6.89 78.85 21.74"
    >
      <g id="a__aufbau_2_">
        <path
          id="a__aufbau_1_"
          d="M12.42,20.31c0-2.02-1.48-3.55-3.49-3.55c-2.02,0-3.49,1.521-3.49,3.55c0,2.04,1.48,3.56,3.49,3.56
		C10.94,23.87,12.42,22.35,12.42,20.31z M17.53,12.5v15.6h-4.15L12.92,27c-1.28,1.04-2.9,1.63-4.71,1.63
		c-4.77,0-8.18-3.479-8.18-8.33c0-4.83,3.41-8.27,8.18-8.27c1.84,0,3.48,0.61,4.77,1.68l0.55-1.21H17.53z"
        />
      </g>
      <g id="e2_blue__aufbau_1_">
        <path
          id="e2_blue__aufbau"
          d="M52.24,20.31c0-2.02-1.48-3.55-3.49-3.55c-2.02,0-3.49,1.521-3.49,3.55c0,2.04,1.48,3.56,3.49,3.56
		C50.76,23.87,52.24,22.35,52.24,20.31z M57.35,12.5v15.6H53.2L52.74,27c-1.28,1.04-2.9,1.63-4.71,1.63
		c-4.77,0-8.18-3.479-8.18-8.33c0-4.83,3.41-8.27,8.18-8.27c1.84,0,3.48,0.61,4.77,1.68l0.55-1.21H57.35z"
        />
      </g>
      <g id="e2_black__aufbau_1_">
        <path
          id="e2_black__aufbau"
          d="M52.24,20.31c0-2.02-1.48-3.55-3.49-3.55c-2.02,0-3.49,1.521-3.49,3.55c0,2.04,1.48,3.56,3.49,3.56
		C50.76,23.87,52.24,22.35,52.24,20.31z M57.35,12.5v15.6H53.2L52.74,27c-1.28,1.04-2.9,1.63-4.71,1.63
		c-4.77,0-8.18-3.479-8.18-8.33c0-4.83,3.41-8.27,8.18-8.27c1.84,0,3.48,0.61,4.77,1.68l0.55-1.21H57.35z"
        />
      </g>
      <g id="p_blue__aufbau_1_">
        <path
          id="p_blue__aufbau"
          d="M25.871,20.319c0,2.051,1.48,3.57,3.49,3.57c2.01,0,3.49-1.52,3.49-3.57
		c0-2.049-1.48-3.569-3.49-3.569C27.36,16.75,25.871,18.271,25.871,20.319z M20.471,20.319c0-4.859,3.41-8.33,8.18-8.33
		c1.41,0,2.7,0.371,3.8,1.041V6.89h5.31v21.23h-3.65l-0.67-1.24c-1.29,1.109-2.94,1.75-4.79,1.75
		C23.88,28.63,20.471,25.18,20.471,20.319z"
        />
      </g>
      <g id="p_black__aufbau_1_">
        <path
          id="p_black__aufbau"
          d="M25.871,20.319c0,2.051,1.48,3.57,3.49,3.57c2.01,0,3.49-1.52,3.49-3.57
		c0-2.049-1.48-3.569-3.49-3.569C27.36,16.75,25.871,18.271,25.871,20.319z M20.471,20.319c0-4.859,3.41-8.33,8.18-8.33
		c1.41,0,2.7,0.371,3.8,1.041V6.89h5.31v21.23h-3.65l-0.67-1.24c-1.29,1.109-2.94,1.75-4.79,1.75
		C23.88,28.63,20.471,25.18,20.471,20.319z"
        />
      </g>
      <g id="line_15__aufbau_1_">
        <polygon id="line_15__aufbau" points="61.24,28.09 78.85,28.09 78.85,24.141 61.24,24.141 61.24,26.12 	" />
      </g>
      <g id="line_14__aufbau_1_">
        <polygon id="line_14__aufbau" points="61.24,28.09 78.85,28.09 78.85,24.141 61.24,24.141 61.24,26.12 	" />
      </g>
      <g id="line_12__aufbau_1_">
        <polygon id="line_12__aufbau" points="40.93,28.09 58.54,28.09 58.54,24.141 40.93,24.141 40.93,26.12 	" />
      </g>
      <g id="line_7__aufbau_1_">
        <polygon id="line_7__aufbau" points="20.92,28.09 38.53,28.09 38.53,24.141 20.92,24.141 20.92,26.12 	" />
      </g>
    </svg>
  );
};

export default AdaIcon;
