import { TFunction } from "i18next";
import { methodType } from "../back/APIManager";
import { ArticleFilters } from "../../pages/OtherSources/OtherSources";
import { ARTICLES_LIST_NUM_ARTICLES_PER_PAGE } from "../../constants/appConfigConstants";

class TranslationManager {
  endpoint: string;
  showNotificationError: (msg: string, isSticky?: boolean, onClose?: () => void) => void;
  t: TFunction;
  token: string;

  constructor(
    endpoint: string,
    token: string,
    showNotificationError: (msg: string, isSticky?: boolean, onClose?: () => void) => void,
    t: TFunction,
  ) {
    this.endpoint = endpoint;
    this.showNotificationError = showNotificationError;
    this.t = t;
    this.token = token;
  }

  private async handleErrors(response: Response) {
    if (response.ok) {
      return;
    }

    switch (response.status) {
      case 500:
      case 503:
        this.showNotificationError(this.t("networkErrors:translations503"));
        break;
      case 401:
        this.showNotificationError(this.t("networkErrors:translations401"));
    }
  }

  buildParams(rawParams: ArticleFilters): Record<string, string> {
    const out: Record<string, string> = {
      "filter[limit]": String(ARTICLES_LIST_NUM_ARTICLES_PER_PAGE),
      "filter[offset]": String(rawParams.offset),
    };
    const keys = Object.keys(rawParams);
    keys.map((el) => {
      if (el === "title" && rawParams[el]) {
        out["filter[where][translated_title][ilike]"] = `%${rawParams[el]}%`;
      } else if (el === "originalTitle" && rawParams[el]) {
        out["filter[where][original_title][ilike]"] = `%${rawParams[el]}%`;
      } else if (el === "site" && typeof rawParams[el] === "string") {
        out["filter[where][site][eq]"] = String(rawParams[el]);
      } else if (el === "content_type" && typeof rawParams[el] === "string") {
        out["filter[where][content_type][eq]"] = String(rawParams[el]);
      } else if (el === "author" && rawParams[el]) {
        out["filter[where][author][ilike]"] = `%${rawParams[el]}%`;
      } else if (el === "sort" && rawParams[el]) {
        if (rawParams.sort) out["filter[order]"] = `created_at ${rawParams.sort.direction}`;
      }
    });
    return out;
  }

  async request<T>(method: methodType, rawParams?: ArticleFilters, id?: string): Promise<T | null> {
    try {
      const params: Record<string, string> | null = rawParams ? this.buildParams(rawParams) : null;
      let endpoint = `${this.endpoint}/articles`;
      if (id) {
        endpoint = `${endpoint}/${id}`;
      }
      const url = new URL(endpoint);

      if (params) {
        url.search = new URLSearchParams(params).toString();
      }

      const headers: HeadersInit = {
        Authorization: `${this.token}`,
      };

      if (method !== "GET") {
        headers["Content-Type"] = "application/json";
      }

      const parsedUrl = url.toString();

      const response = await fetch(parsedUrl, {
        method: method,
        headers: headers,
      });

      if (!response.ok) {
        this.handleErrors(response);
        return null;
      }

      const jsonResponse = await response.json();

      return jsonResponse as T;
    } catch {
      return null;
    }
  }
}

export default TranslationManager;
