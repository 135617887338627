import React, { lazy } from "react";
import { Switch, Route } from "react-router";
import userRoles from "../../constants/userRoles";
import Paths from "./Paths";
import PrivateRoute from "./PrivateRoute";

const LoginPage = lazy(() => import("../../pages/LoginPage/LoginPage"));
const Error404Page = lazy(() => import("../../pages/Error404Page/Error404Page"));
const ArticlePage = lazy(() => import("../../pages/Articles/Article/ArticlePage"));
const DraftsPage = lazy(() => import("../../pages/Articles/Drafts/ArticleDrafts"));
const SelectSitePage = lazy(() => import("../../pages/Articles/SelectSite/SelectSitePage"));
const ArticleList = lazy(() => import("../../pages/Articles/ArticlesList/ArticlesList"));
const DebugTools = lazy(() => import("../../pages/debug/DebugTools"));
const PrivateTools = lazy(() => import("../../pages/debug/PrivateTools"));
const ImportBIList = lazy(() => import("../../pages/Articles/ImportBI/ImportBiList"));
const CardsForm = lazy(() => import("../../pages/Cards/CardsForm/CardsForm"));
const CardsList = lazy(() => import("../../pages/Cards/CardsList/CardsList"));
const CoversEditorPage = lazy(() => import("../../pages/Covers/CoversEditorPage"));
const DynamicCardsEditor = lazy(() => import("../../pages/Cards/DynamicCardsEditor/DynamicCardsEditor"));
const TagsDashboard = lazy(() => import("../../pages/TagsManager/TagsDashboard"));
const TagPagesEditor = lazy(() => import("../../pages/TagsManager/Sections/TagPagesEditor"));
const EntityBarEditor = lazy(() => import("../../pages/EntityBar/EntityBarEditor"));
const ProductsForm = lazy(() => import("../../pages/Products/Form/ProductForm"));
const ProductsList = lazy(() => import("../../pages/Products/List/ProductList"));
const PagesEditor = lazy(() => import("../../pages/Products/Pages/PagesEditor"));
const BrandsPages = lazy(() => import("../../pages/Products/BrandsPages/BrandsPages"));
const BrandsList = lazy(() => import("../../pages/Products/BrandsList/BrandsList"));
const FraturedContentManager = lazy(() => import("../../pages/FeaturedContentManager/FeaturedContentManager"));
const RedirectionsForm = lazy(() => import("../../pages/Redirections/Form/RedirectionsForm"));
const RedirectionList = lazy(() => import("../../pages/Redirections/List/RedirectionList"));
const OtherSources = lazy(() => import("../../pages/OtherSources/OtherSources"));

const Routes: React.FC = () => (
  <Switch>
    <Route exact path={Paths.Login}>
      <LoginPage />
    </Route>
    <PrivateRoute path={Paths.Article.SelectSite}>
      <SelectSitePage nodeType="articles" />
    </PrivateRoute>
    <PrivateRoute path={`${Paths.Article.ArticleDiff}/:site/:uuid/:version`}>
      <ArticlePage nodeType="articles" />
    </PrivateRoute>
    <PrivateRoute path={`${Paths.Article.NewArticle}/:site/:uuid`} isArticle={true}>
      <ArticlePage nodeType="articles" />
    </PrivateRoute>
    <PrivateRoute path={Paths.Article.Drafts}>
      <DraftsPage />
    </PrivateRoute>
    <PrivateRoute path={Paths.Article.ListArticles}>
      <ArticleList nodeType="articles" />
    </PrivateRoute>
    <PrivateRoute path={Paths.Article.Debug}>
      <DebugTools />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={Paths.Article.Tools}>
      <PrivateTools />
    </PrivateRoute>
    <PrivateRoute path={Paths.Article.importBI}>
      <ImportBIList />
    </PrivateRoute>
    <PrivateRoute path={Paths.Specials.SpecialsSites}>
      <SelectSitePage nodeType="specials" />
    </PrivateRoute>
    <PrivateRoute path={Paths.Specials.SpecialsList}>
      <ArticleList nodeType="specials" />
    </PrivateRoute>
    <PrivateRoute path={`${Paths.Specials.SpecialDiff}/:site/:uuid/:version`} isArticle={true} nodeType="specials">
      <ArticlePage nodeType="specials" />
    </PrivateRoute>
    <PrivateRoute path={`${Paths.Specials.Special}/:site/:uuid`} isArticle={true} nodeType="specials">
      <ArticlePage nodeType="specials" />
    </PrivateRoute>
    <PrivateRoute path={`${Paths.Cards.Create}/:uuid`} isArticle={true}>
      <CardsForm />
    </PrivateRoute>
    <PrivateRoute path={Paths.Cards.Create}>
      <CardsForm />
    </PrivateRoute>
    <PrivateRoute path={Paths.Cards.List}>
      <CardsList />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={Paths.Covers.SelectSite}>
      <SelectSitePage nodeType="covers" />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={`${Paths.Covers.CoverEdition}/:site`}>
      <CoversEditorPage />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={Paths.Cards.DynamicCardsSites}>
      <SelectSitePage nodeType="dynamicCard" />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={`${Paths.Cards.DynamicCardsedition}/:site`}>
      <DynamicCardsEditor />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={`${Paths.Tags.EditTagPages}/:uuid`}>
      <TagPagesEditor />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER, userRoles.TAGS_MANAGER]} path={`${Paths.Tags.Dashboard}`}>
      <TagsDashboard />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={Paths.EntityBar.SelectSite}>
      <SelectSitePage nodeType="entityBar" />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={`${Paths.EntityBar.EntityBarEdition}/:site`}>
      <EntityBarEditor />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER, userRoles.PRODUCT_ENTITIES_MANAGER]} path={Paths.Products.Add}>
      <ProductsForm />
    </PrivateRoute>
    <PrivateRoute
      minRol={[userRoles.WEBMANAGER, userRoles.PRODUCT_ENTITIES_MANAGER]}
      path={`${Paths.Products.Edit}/:uuid`}
    >
      <ProductsForm />
    </PrivateRoute>
    <PrivateRoute
      minRol={[userRoles.WEBMANAGER, userRoles.PRODUCT_ENTITIES_MANAGER]}
      path={`${Paths.Products.Pages}/:uuid`}
    >
      <PagesEditor />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER, userRoles.PRODUCT_ENTITIES_MANAGER]} path={Paths.Products.List}>
      <ProductsList />
    </PrivateRoute>
    <PrivateRoute
      minRol={[userRoles.WEBMANAGER, userRoles.PRODUCT_ENTITIES_MANAGER]}
      path={`${Paths.Products.BrandsPages}/:uuid`}
    >
      <BrandsPages />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER, userRoles.PRODUCT_ENTITIES_MANAGER]} path={Paths.Products.BrandsList}>
      <BrandsList />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={Paths.Article.FeaturedSelectSite}>
      <SelectSitePage nodeType="featuredContents" />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.WEBMANAGER]} path={`${Paths.Article.FeaturedManagement}/:site`}>
      <FraturedContentManager />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.REDIRECTIONS_MANAGER]} path={Paths.Redirections.Form}>
      <RedirectionsForm />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.REDIRECTIONS_MANAGER]} path={Paths.Redirections.List}>
      <RedirectionList />
    </PrivateRoute>
    <PrivateRoute minRol={[userRoles.TRANSLATION_MANAGER]} path={Paths.Article.OtherSources}>
      <OtherSources />
    </PrivateRoute>
    <Route>
      <Error404Page />
    </Route>
  </Switch>
);

export default Routes;
